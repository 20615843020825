<template>
  <table>

      <thead>
        <tr>
          <th>What to read?</th>
          <th>Why this section?</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in content" :key="index">
          <td> {{ item.title }} </td>
          <td>
            <p> {{ item.details.description }} </p>
            <ul>
              <li v-for="(use_case, detail_index) in item.details.use_cases" :key="detail_index">
                {{ use_case }}
              </li>
            </ul>
          </td>
        </tr>
      </tbody>

    </table>
</template>

<script>

export default {
  data() {
    return {
      content: [
        {
          title: 'Background and References',
          details: {
            description: 'Read up-to-date, detailed information about a condition or category of molecular tests.',
            use_cases: [
              'Re-familiarize yourself with content to prepare for questions/conversations with providers',
              'Gain more knowledge about a condition\'s symptoms, management and current treatment',
              'Increase awareness of the testing landscape for a condition',
              'Find primary literature references for evaluation of a test or knowledge of a condition'
            ]
          }
        },
        {
          title: 'Decision Aids',
          details: {
            description: 'Quickly find pertinent facts to guide decision-making',
            use_cases: [
              'Concise summaries about genetic conditions',
              'Common questions & answers about molecular tests',
              'Scenarios for which molecular testing has clinical utility',
              'Unique considerations for a particular clinical category',
              'Flowcharts (clinical algorithms) for testing indications',
              'Citations for key professional society guidelines and/or reference articles'
            ]
          }
        },
        {
          title: 'Coverage Guidelines',
          details: {
            description: 'Review InformedDNA\'s evidence-based coverage criteria',
            use_cases: [
              'Understand whether molecular testing for the indication under review is indicated',
              'Determine if molecular testing is contraindicated for a specific clinical indication',
              'Identify recommended criteria by which to evaluate for a specific diagnosis or molecular test',
              'Ascertain what specific clinical information (e.g., lab values, age of diagnosis, tumor size/stage) may be needed from a medical record for clinical review'
            ]
          }
        },
        {
          title: 'CPT® Code Insights',
          details: {
            description: 'Find insightful information based on CPT® code provided',
            use_cases: [
              'Find the proprietary test name/specific gene(s) and associated lab, if applicable, to help identify the test for review',
              'To see if the CPT® code is associated with a specific test and lab (which may clarify what a test is if it is entered by the provider as a custom test)',
              'Read a CPT® code\'s description (e.g., number of genes on a panel, methodology of testing) to determine whether the test meets medical necessity criteria or gauge if the CPT® code provided accurately represents the test under review',
              'Identify the CPT® code\'s effective date to help inform an administrative review and determine if the code was active on the date of service',
              'Understand InformedDNA\'s medical necessity designation based on our guidelines',
              'Locate applicable InformedDNA coverage guideline(s) to ascertain our medical necessity criteria',
            ]
          }
        },
      ]
    }
  },
}

</script>

<style scoped>

table {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #99acc2;
}

th, td {
  padding: 4px 10px;
}

th:first-child, td:first-child {
  width: 20%;
}

th {
  font-weight: 500;
  color: white;
  background-color: #004568;
}

td {
  border: 1px solid #eeeeee;
}

td:first-child {
  font-weight: bold;
  color: black;
}

ul {
  font-size: 20px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}


p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}
</style>
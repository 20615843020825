<template>
  <v-container fluid class="pa-0">
    <PostContent :post="post">
      <template v-slot:append-title>
        <p v-if="formattedReleaseDate" class="last-updated">
          LAST UPDATED ON: {{ formattedReleaseDate }}
        </p>
      </template>
    </PostContent>
  </v-container>
</template>

<script>

  import PostContent from '@/components/posts/PostContent.vue';

  export default {
    props: {
      post: {
        default: {},
        required: true
      }
    },
    computed: {
      formattedReleaseDate() {

        if (!this.post.release_date) {
          return '';
        }

        const year = this.post.release_date.substr(0, 4);
        const month = this.post.release_date.substr(4, 2);
        const day = this.post.release_date.substr(6, 2);

        return month + '/' + day + '/' + year;
      },
    },
    components: {
      PostContent,
    },
  }
</script>

<style scoped>

</style>
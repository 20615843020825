<template>
  <PageWithFooter>
    <template v-slot:content>
      <v-container fluid class="default-gutter">
        <v-row>
          <PageHeader/>
          <PostSearchBreadcrumbs :route="breadcrumbsRoute" :item="searchTerm" class="mb-8"/>
          <GuidelinesSearchHeader class="mb-10"/>
          <PostSearchBar :initial-term="searchTerm" @searchByTerm="updateUrlQuery" :post-type="'coverage guidelines'">
            <template v-slot:placeholder>
              <span class="search-label">Search for <b>Coverage Guidelines</b> by Disease names, ICD-10 Codes, CPT® Codes, Genes</span>
            </template>
          </PostSearchBar>
          <PostSearchTotalResult :total-result="totalPosts" class="mb-8" />
          <GuidelinesSearchResultList v-if="(!isRunningSearch && !noResultsFound)" :posts="posts" class="mb-10"/>
          <SearchLoading v-if="(isRunningSearch && !noResultsFound)" class="mb-12"/>
          <SearchNoResults v-if="(!isRunningSearch && noResultsFound)" :ring="true"/>
        </v-row>
      </v-container>
    </template>
  </PageWithFooter>
</template>

<script>

  import PageWithFooter from '@/components/PageWithFooter';
  import PageHeader from '@/components/PageHeader';
  import PostSearchBreadcrumbs from '@/components/search/posts/PostSearchBreadcrumbs';
  import GuidelinesSearchHeader from '@/components/search/guidelines/GuidelinesSearchHeader';
  import PostSearchBar from '@/components/search/posts/PostSearchBar';
  import PostSearchTotalResult from '@/components/search/posts/PostSearchTotalResult';
  import GuidelinesSearchResultList from '@/components/search/guidelines/GuidelinesSearchResultList';
  import SearchLoading from '@/components/search/SearchLoading';
  import SearchNoResults from '@/components/search/SearchNoResults';
  import { PostSearch } from '@/mixins/PostSearch';
  import { guidelinesStore } from '@/store/guidelines';

  export default {
    data() {
      return {
        breadcrumbsRoute: {
          name: 'search-guidelines',
          description: 'Coverage Guidelines',
          path: '/search/guidelines'
        },
      }
    },
    mixins: [PostSearch],
    beforeMount: function() {
      this.setSearchTerm();
      this.search();
    },
    computed: {
      store: () => guidelinesStore(),
    },
    components: {
      PageWithFooter,
      PageHeader,
      PostSearchBreadcrumbs,
      GuidelinesSearchHeader,
      PostSearchBar,
      PostSearchTotalResult,
      GuidelinesSearchResultList,
      SearchLoading,
      SearchNoResults
    },
  }
</script>

<template>
  <page-with-footer>
    <template v-slot:content>
      <page-header class="default-gutter"/>
      <global-search-bar class="default-gutter mt-6 mb-4" @selected="selected"/>
      <page-breadcrumbs class="mb-8" />
      <guideline-container/>
    </template>
    <template v-slot:footer>
      <cta-sign-up-banner v-if="!$currentUser.loggedIn()" />
      <page-footer />
    </template>
  </page-with-footer>
</template>

<script>
  // @ is an alias to /src
  import PageHeader from "@/components/PageHeader";
  import GuidelineContainer from "@/components/posts/guideline/GuidelineContainer";
  import PageWithFooter from "@/components/PageWithFooter";
  import GlobalSearchBar from "@/components/search/global/GlobalSearchBar";
  import PageBreadcrumbs from "@/components/PageBreadcrumbs";
  import CtaSignUpBanner from "@/components/call-to-action/CtaSignUpBanner";
  import PageFooter from "@/components/PageFooter";
  import {searchTermsStore} from "@/store/search-terms";

  export default {
    components: {
      PageFooter,
      CtaSignUpBanner,
      PageBreadcrumbs,
      PageWithFooter,
      PageHeader,
      GuidelineContainer,
      GlobalSearchBar
    },
    computed: {
      terms: () => searchTermsStore()
    },
    methods: {
      selected: function (item) {
        this.$router.push({
          path: '/search',
          query: {
            q: item?.name,
            t: item?.id
          }
        }).catch(() => {
        });
      }
    },
    mounted() {
      if (this.$route.query?.t && this.$route.query?.q) {
        this.terms.displayedItem = {
          name: this.$route.query.q,
          id: this.$route.query.t
        };
      }
    }
  }
</script>
<template>
  <v-container fluid>
    <v-row v-if="!!post.title" class="py-0">
      <v-col>
        <h3 class="post-title">{{ post.title }}</h3>
        <slot name="append-title"/>
      </v-col>
    </v-row>
    <v-row class="mb-7 py-0">
      <v-col col="12" class="post-content-parent py-0">
        <v-container
          fluid
          v-html="content"
          class="post-content pa-0"
          :class="{'not-subscribed': !$currentUser.loggedIn()}"
        />
        <slot name="append-end-content"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {
      post: {
        default: {},
        required: true
      }
    },
    computed: {
      content() {
        return this.$currentUser.loggedIn() ? this.post.content : this.post.preview;
      }
    }
  }
</script>

<style scoped>
  :deep(.post-content-parent) {
    position: relative;
  }

  :deep(.post-content) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  :deep(.post-content h3), h3.post-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    color: #45B98A;
  }

  :deep(.post-content h3) {
    margin: 10px 0;
  }

  :deep(.post-content h4) {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #EDA341;
    margin: 10px 0;
  }

  :deep(.post-content h4:first-child) {
    margin-top: 0;
  }

  :deep(.post-content ul), :deep(.post-content ol) {
    margin-bottom: 1.5em;
  }

  :deep(.post-content ul) > li, :deep(.post-content ol) > li {
    margin-left: 35px;
  }

  :deep(.post-content ul ul), :deep(.post-content ol ol), :deep(.post-content ul ol), :deep(.post-content ol ul) {
    margin-bottom: 0;
  }

  :deep(.post-content p) {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  :deep(.post-content hr) {
    margin-block-start: 15px;
    margin-block-end: 15px;
  }


  /* Tables that are copied and pasted in */
  :deep(.post-content table) {
    border-width: 0;
    border-collapse: collapse;
    width: auto;
    max-width: 100%;
  }

  :deep(.post-content table) > tbody > tr > td {
    padding: 14px 40px;
    border-width: 0;
    vertical-align: top;
  }

  :deep(.post-content table) > tbody > tr:not(:first-child) > td:first-child, .post-content :deep(table) > tbody > tr:not(:first-child) > td:first-child {
    background-color: #E0DFDE;
    color: #000000;
    border-bottom: 2px solid #FFFFFF;
  }

  :deep(.post-content table) > tbody > tr:not(:first-child) > td:not(:first-child), table > tbody > tr:not(:first-child) > td:not(:first-child) {
    background-color: #FFFFFF;
    color: #000000;
    border-bottom: 2px solid #E0DFDE;
    border-left: 8px solid #FFFFFF;
  }

  :deep(.post-content table) > tbody > tr:first-child {
    background-color: #0B578B;
    color: #FFFFFF;
  }

  :deep(.post-content table) > tbody > tr:first-child > td:not(:first-child) {
    border-left: 8px solid #FFFFFF;
  }

  :deep(.post-content table) > tbody > tr:not(:first-child) {
    background-color: #E0DFDE;
    color: #000000;
  }

  /* Table for the Clinical Utility Score */
  :deep(.post-content table.clinical-utility-score) {
    border-width: 0;
    border-collapse: collapse;
    width: 440px;
  }

  :deep(.post-content table.clinical-utility-score) > tbody > tr > td {
    padding-top: 22px;
    padding-bottom: 22px;
    border-width: 0;
  }

  :deep(.post-content table.clinical-utility-score) > tbody > tr:first-child {
    background-color: #EDA341;
    color: #FFFFFF;
  }

  :deep(.post-content table.clinical-utility-score) > tbody > tr:nth-child(2) {
    background-color: #E0DFDE;
    color: #000000;
  }

  :deep(.post-content img) {
    max-width: 100%;
    height: auto;
  }

  :deep(.post-content table.single-column-table) {
    width: 100%;
    border-collapse: collapse;
    border: solid 2px white;
  }

  :deep(.post-content table.single-column-table) th {
    padding: 20px 30px;
    border: 2px solid white;
    background-color: #0B578B;
    color: #FFFFFF;
  }

  :deep(.post-content table.single-column-table) td {
    padding: 10px 30px;
    border: 2px solid white;
    background-color: inherit !important;
  }

  :deep(.post-content table.single-column-table) tr:nth-child(even) {
    background-color: #E0DFDE;
  }

  :deep(.post-content table.single-column-table) tr:nth-child(odd) {
    background-color: #FFFFFF;
  }

  :deep(.post-content hr) {
    clear: both;
  }

  .post-content.not-subscribed::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(transparent, white);
  }

  :deep(.post-content a) {
    color: #000000;
  }

  :deep(.post-content a:hover) {
    color: #45B98A;
  }
</style>

<style>
  .alignright {
    float: right;
    margin: 0.5em 0 0.5em 1em;
  }

  .alignleft {
    float: left;
    margin: 0.5em 1em 0.5em 0;
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .last-updated{
    color: #888888;
    margin-top: 1.5rem;
    font-size: 1rem;
  }
</style>

import {defineStore} from 'pinia'

export const breadcrumbsStore = defineStore('breadcrumbs', {
    state: () => ({
        items: [],
        current: '',
    }),
    actions: {
        replace: function (item) {
            this.items.pop();
            this.items.push(item);
        },
        push: function (item) {
            if (Array.isArray(item)) {
                this.items.push(...item);
                return true;
            }
            if (typeof item === 'object') {
                this.items.push(item);
                return true;
            }
            return false;
        },
        pushRoute: function(route, text, disabled = false) {
            this.push({
                to: {
                    path: route.path,
                    name: route.name,
                    params: route.params,
                    query: route.query
                },
                text: text,
                disabled: disabled
            });
        },
        replaceRoute: function (route, text, disabled = true) {
            this.items.pop();
            this.pushRoute(route, text, disabled);
        },
        pop: function () {
            return this.items.pop();
        },
        last: function () {
            if (this.items.length) {
                return this.items[this.items.length - 1];
            }
            return null;
        },
        enableLast: function() {
            if (this.last()) {
                this.last().disabled = false;
            }
        },
        disableLast: function() {
            if (this.last()) {
                this.last().disabled = true;
            }
        },
        clear: function () {
            this.items = [];
        },
        load: function(router) {
            if (this.current !== router.path) {
                this.clear();
            }
        }

    },
    persist: true
})

<template>
  <v-pagination
    :value="page"
    :length="totalPages"
    :total-visible="11"
    :disabled="disabled"
    @input="changePage"
    circle
    color="#EDA341 !important"
  ></v-pagination>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
      required: false
    },
    itemsPerPage: {
      type: Number,
      default: 36,
      required: false
    },
    totalResult: {
      type: Number,
      default: 300,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', { page });
    }
  },
  computed: {
    totalPages() {
      return parseInt(Math.ceil(this.totalResult / this.itemsPerPage));
    }
  }
}
</script>

<style scoped>
:deep(button:enabled > i.v-icon) {
  color: #EDA341;
}
:deep(button > i.v-icon) {
  font-size: 36px;
}
</style>
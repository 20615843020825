import { render, staticRenderFns } from "./ForgotPasswordView.vue?vue&type=template&id=b7ccd468&"
import script from "./ForgotPasswordView.vue?vue&type=script&lang=js&"
export * from "./ForgotPasswordView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <v-container>
    <v-row align-content="center">
      <v-col>
        <v-container class="form-container">
          <validation-observer
              ref="observer"
              v-slot="{ valid, validate }"
          >
            <v-form @submit.prevent="validate().then(login)">
              <v-row dense class="mb-8">
                <v-col align="center"><h2>Welcome to <b>InformedGENOMICS</b></h2></v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      vid="email"
                      name="Email"
                      rules="required|email"
                      ref="email"
                  >
                    <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        class="rounded-sm login"
                        color="#333333"
                        label="Email"
                        outlined
                        autocomplete="username"
                        @input="revalidate"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      vid="password"
                      name="Password"
                      rules="required"
                      ref="password"
                  >
                    <v-text-field
                        v-model="password"
                        :error-messages="errors"
                        class="rounded-sm login"
                        color="#333333"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        label="Password"
                        @click:append="show = !show"
                        outlined
                        autocomplete="current-password"
                        @input="revalidate"
                    >
                      <template v-slot:append>
                        <v-img v-if="show" src="@/assets/icon-eye.svg" @click="show = !show" class="icon-hover"/>
                        <v-img v-else src="@/assets/icon-eye-closed.svg" @click="show = !show" class="icon-hover"/>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row class="mb-6" dense>
                <v-col>
                  <v-btn
                      class="continue rounded-sm no-uppercase"
                      :class="{active: valid && email && password}"
                      type="submit"
                      x-large text
                      :disabled="!valid || !email || !password"
                  >
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
          <v-row dense>
            <v-col align="center">
              <button class="forgot" @click="$router.push({name:'forgot-password', params: {email: email}})">I forgot my
                password
              </button>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    data() {
      return {
        show: false,
        email: "",
        password: "",
        loginFailed: false
      }
    },
    props: {
      routePath: {
        type: String,
        default: '/',
      },
      routeParams: {
        type: Object,
        default: () => {
          return {}
        }
      },
      routeQuery: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    computed: {
      sendUserBack() {
        if (this.$route.query.redirect !== 'back') {
          return false;
        }

        return this.$router.history._startLocation !== this.$route.fullPath;
      }
    },
    methods: {
      login: async function () {

        if (!this.$refs.observer.validate()) {
          return false;
        }

        this.$currentUser.login(this.email, this.password)
            .then(() => {
              if (!this.$currentUser.token) {
                this.$refs.observer.setErrors({
                  email: [''],
                  password: ["No account was found or the email and/or password entered was incorrect. Please try again."]
                });
                this.loginFailed = true;
                throw new Error('Authentication failed');
              }

              if (this.sendUserBack) {
                return this.$router.back();
              }

              this.$router.push({
                path: this.routePath,
                params: this.routeParams,
                query: this.routeQuery
              })
            }).catch(() => {

            });
      },
      revalidate: function () {
        if (this.loginFailed) {
          this.loginFailed = false;
          this.$refs.observer.reset();
        }
      },
    },
  }
</script>

<style scoped>
  h2 {
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    color: #004568;
  }

  .form-container {
    background-color: #FFFFFF;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    margin: 30px auto;
    max-width: 770px;
    padding: 40px 56px;
  }


  .continue {
    width: 100%;
    padding: 27px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    background-color: #EEEEEE;
  }

  .continue.active {
    background-color: #EDA341;
    color: #FFFFFF;
  }

  .continue.active:hover {
    opacity: 0.7;
  }

  button.forgot {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #EDA341;
  }

  .login {
    color: #333333;
  }

  .login :deep(input) {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
  }

  .login :deep(label) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .no-uppercase {
    text-transform: unset !important;
  }

  .icon-hover {
    cursor: pointer;
  }
</style>
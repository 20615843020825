import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import ContextView from '@/views/ContextView'
import SearchGlobalView from '@/views/SearchGlobalView'
import GuidelineSearchView from '@/views/GuidelineSearchView'
import GuidelinesSearchDetailView from "@/views/GuidelinesSearchDetailView.vue";
import GuidelineView from "@/views/GuidelineView";
import GuidelinePreviousVersionsView from '@/views/GuidelinePreviousVersionsView';
import BackgroundSearchView from '@/views/BackgroundSearchView';
import BackgroundDetailView from "@/views/BackgroundDetailView.vue";
import CPTCodeSearchView from '@/views/CPTCodeSearchView';
import CPTCodeChangeSummaryView from '@/views/CPTCodeChangeSummaryView';
import LoginView from "@/views/LoginView";
import ForgotPasswordView from "@/views/ForgotPasswordView";
import ResetPasswordView from "@/views/ResetPasswordView";
import ContactUsView from "@/views/ContactUsView";
import EvidentiaryReviewView from "@/views/EvidentiaryReviewView";
import CptCodeScopeOfInclusionView from "@/views/CptCodeScopeOfInclusionView";
import TermsOfUseView from "@/views/TermsOfUseView";
import PrivacyPolicyView from "@/views/PrivacyPolicyView";
import EndUserLicenseAgreementView from "@/views/EndUserLicenseAgreementView";
import DownloadView from "@/views/DownloadView";
import HelpCenterView from '@/views/HelpCenterView';
import PageNotFoundView from '@/views/PageNotFoundView';

import pinia from "@/store";
import {breadcrumbsStore} from "@/store/breadcrumbs";
import {ctaModalStore} from "@/store/cta-modal";
import {currentUserStore} from "@/store/current-user";
import {searchTermsStore} from "@/store/search-terms";
import {searchTermsGuidelinesStore} from "@/store/search-terms-guidelines";
import CaseProcessingChangeSummaryView from "@/views/CaseProcessingChangeSummaryView.vue";

Vue.use(VueRouter)

const breadcrumbs = breadcrumbsStore(pinia);
const ctaModal = ctaModalStore(pinia);
const currentUser = currentUserStore(pinia);
const globalTerms = searchTermsStore(pinia);
const guidelineTerms = searchTermsGuidelinesStore(pinia);

function displayCtaModal() {
    if (!currentUser.loggedIn() && !ctaModal.registered) {
        ctaModal.displayAfterInactivity();
    }
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        beforeEnter: (to, from, next) => {
            breadcrumbs.clear();
            globalTerms.$reset();
            next();
        },
    },
    {
        path: '/search/guidelines',
        name: 'search-guidelines',
        component: GuidelineSearchView,
        props: true,
        beforeEnter: (to, from, next) => {
            if (!to.query.t && !to.query.q) {
                guidelineTerms.$reset();
            }
            next();
        },
        meta: {
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/search/guidelines/:id',
        name: 'search-guidelines-detail',
        component: GuidelinesSearchDetailView,
        props: true,
        meta: {
            displayCtaModal: true,
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/search/references',
        name: 'search-references',
        component: BackgroundSearchView,
        props: true,
        meta: {
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/search/references/:id',
        name: 'search-references-detail',
        component: BackgroundDetailView,
        props: true,
        meta: {
            displayCtaModal: true,
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/search/cpt-codes',
        name: 'search-cpt-codes',
        component: CPTCodeSearchView,
        props: true,
        meta: {
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/cpt-codes/change-summary',
        name: 'cpt-codes-change-summary',
        component: CPTCodeChangeSummaryView,
        props: true,
        meta: {
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/decision-aids/:id/change-summary',
        name: 'decision-aids-change-summary',
        component: CaseProcessingChangeSummaryView,
        props: true,
        meta: {
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/search',
        name: 'search-global',
        component: SearchGlobalView,
        props: true,
        meta: {
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/clinical-context/:id',
        name: 'clinical-context',
        component: ContextView,
        props: true,
        meta: {
            displayCtaModal: true,
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/guidelines/:id',
        name: 'guidelines',
        component: GuidelineView,
        props: true,
        meta: {
            displayCtaModal: true,
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/guidelines/:id/previous-versions',
        name: 'guidelines-previous-versions',
        component: GuidelinePreviousVersionsView,
        props: true,
        meta: {
            displayCtaModal: true,
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        props: true,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordView,
        props: true,
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPasswordView,
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUsView,
    },
    {
        path: '/evidentiary-review',
        name: 'evidentiary-review',
        component: EvidentiaryReviewView,
    },
    {
        path: '/cpt-code-scope-of-inclusion',
        name: 'cpt-code-scope-of-inclusion',
        component: CptCodeScopeOfInclusionView,
    },
    {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: TermsOfUseView,
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicyView,
    },
    {
        path: '/end-user-license-agreement',
        name: 'end-user-license-agreement',
        component: EndUserLicenseAgreementView,
        meta: {
            ignoreEndUserLicenseAgreement: true
        }
    },
    {
        path: '/downloads/:file(.*)',
        name: 'download-file',
        component: DownloadView,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/help-center',
        name: 'help-center',
        component: HelpCenterView,
        meta: {
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '/404',
        name: 'page-not-found',
        component: PageNotFoundView,
        meta: {
            requireThirdPartyLicenseAgreement: true
        }
    },
    {
        path: '*',
        redirect: () => {
            return {path: '/'}
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (to.path === from.path)
            return false;

        if (savedPosition) {
            return savedPosition
        }

        return {x: 0, y: 0}
    }
})

let firstRoute = true;

router.beforeEach((to, from, next) => {
    if (firstRoute) {
        breadcrumbs.load(to);
        firstRoute = false;
    }
    breadcrumbs.current = to.path;
    ctaModal.hide();
    ctaModal.cancelTimer();

    next();
})

router.afterEach((to) => {
    if (to.meta.displayCtaModal) {
        displayCtaModal();
    }
})

export default router;

import {defineStore} from 'pinia'

export const cookieStore = defineStore('cookies', {
    getters: {
        apiDomain: function () {
            return this.$env?.api_url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/i)[1];
        },
        siteDomain: () => {
            return window.location.hostname;
        },
        commonDomain: function () {
            const matches = this.apiDomain?.match(/\..*$/);
            return Array.isArray(matches) ? matches[0] : '.' + this.siteDomain();
        }
    },
    actions: {
        set: function(key, value, expires = '90d', path = '/', domain = null, secure = false) {
            domain = domain ?? this.commonDomain;
            secure = domain === this.commonDomain ? secure : true;
            this.$cookies.set(key, value, expires, path, domain, secure);
        },
        remove: function(key, path = '/', domain = null) {
            domain = domain ?? this.commonDomain;
            this.$cookies.remove(key, path, domain);
        }
    },
})

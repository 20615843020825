<template>
  <VContainer fluid class="py-0">
    <VRow dense>
      <SearchBar :store-class="storeClass" :placeholder="''" :initial-term="initialTerm" @selected="selected" class="mb-6">
        <template v-slot:label>
          <span class="search-label">Search the <b>Reference Library</b> by Disease names, ICD-10 Codes, CPT® Codes, Genes</span>
        </template>
      </SearchBar>
    </VRow>
  </VContainer>
</template>

<script>
  import { searchTermsStore } from "@/store/search-terms";
  import SearchBar from "@/components/search/SearchBar";

  export default {
    data() {
      return {
        searchButtonText: "GO",
        placeholder: 'Search the Reference Library by Disease names, ICD-10 Codes, CPT® Codes, Genes',
        storeClass: searchTermsStore
      }
    },
    props: {
      initialTerm: {
        default: null
      }
    },
    methods: {
      selected: function (item) {
        if (item === null) {
          this.terms.reset();
        }

        this.$gtag.event('view_search_results', {
          search_term_id: item?.id ?? '',
          search_term: item?.name ?? '',
          search_type: 'reference library'
        });

        this.$emit('searchByTerm', item);
      },
    },
    computed: {
      terms: () => searchTermsStore(),
    },
    components: {
      SearchBar
    },
    beforeMount() {
      this.terms.reset();
    }
  }
</script>

<style scoped>
  :deep(.v-input__prepend-outer) {
    margin: auto !important;
  }

  :deep(.search-label) {
    font-weight: 400;
    font-size: 20px;
    color: #888888;
  }

  .search-bar-row {
    margin-left: -4px !important;
  }

</style>
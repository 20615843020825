<template>
  <v-container fluid class="footer align-center d-flex">
    <v-row class="my-auto d-flex align-center justify-center">
      <div class="d-inline-flex justify-start logo-container">
        <v-img contain height="96" width="105" src="@/assets/icon-ncqa.svg" class="mr-auto ncqa-logo"/>
      </div>
      <div class="my-auto links-container">
        <div class="d-inline-flex align-center page-links">
          <router-link :to="{path: '/contact-us'}" class="page-link">Contact Us</router-link>
          <div class="page-link-spacer"></div>
          <router-link :to="{path: '/terms-of-use'}" class="page-link">Terms of Use</router-link>
          <div class="page-link-spacer"></div>
          <router-link :to="{path: '/privacy-policy'}" class="page-link">Privacy Policy</router-link>
          <div class="page-link-spacer"></div>
          <a href="https://informeddna.com" target="_blank" class="page-link">InformedDNA®
            <v-img contain height="16" src="@/assets/icon-open-in-new-tab.svg" class="my-auto"></v-img>
          </a>
        </div>
        <div v-if="$currentUser.loggedIn()" class="support-email mt-6">
          informedgenomicssupport@informeddna.com
        </div>
      </div>
      <div class="my-auto right-container">
        <v-row class="justify-end" style="gap:32px">
          <template v-for="(icon, index) in icons">
            <v-btn
                :key="`icons_${index}`"
                icon
                :ripple="false"
                :href="icon.url"
                target="_blank"
                class="social-icon"
            >
              <v-icon color="#FFFFFF" size="32">{{ icon.name }}</v-icon>
            </v-btn>
          </template>
        </v-row>
        <v-row class="copyright-text justify-end mt-8">
          Copyright ©2023 InformedDNA® All Rights Reserved
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>


<script>
  export default {
    data() {
      return {
        icons: [
          {
            name: 'mdi-facebook',
            url: 'https://www.facebook.com/Informeddnageneticexperts/'
          },
          {
            name: 'mdi-linkedin',
            url: 'https://www.linkedin.com/company/informeddna/'
          },
          {
            name: 'mdi-twitter',
            url: 'https://twitter.com/informeddna'
          },
        ]
      }
    }
  }
</script>

<style scoped>
  .footer {
    gap: 40px;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;

    height: 136px;
    background-image: url(@/assets/background-hex-molecule.svg), linear-gradient(180deg, #004568 0%, #083350 100%);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .links-container {
    width: 37%;
  }

  .page-links {
    gap:5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    width: 100%;
    justify-content: space-between;
  }

  .page-link {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-decoration: none;
    white-space: nowrap;
  }

  a.page-link {
    display: inline-flex;
    align-content: center;
    gap: 4px;
  }

  .page-link-spacer {
    width: 20px;
    height: 0;
    left: 931px;
    top: 42px;
    border: 1px solid #FFFFFF;
    transform: rotate(90deg);
  }

  .logo-container {
    position: absolute;
    left: 100px;
  }

  .right-container {
    position: absolute;
    right: 100px;
  }

  .ncqa-logo:deep(.v-image__image, .v-image__placeholder) {
    width: 105px;
  }

  .copyright-text {
    white-space: nowrap;
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }

  :deep(a.social-icon) :hover {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .support-email {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
</style>

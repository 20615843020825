<template>
  <v-container fluid class="mt-8">
    <v-row class="justify-space-between flex-nowrap ma-0">
      <div class="d-inline-flex align-center" style="gap:16px">
        <page-navigation/>
        <v-img src="@/assets/idna-logo.png" width="266px"/>
      </div>
      <div class="d-inline-flex align-center">
        <button @click="$router.push({name: 'home'}).catch(() => {})" class="header-link mr-14 nowrap">Home</button>
        <button @click="$router.push({name: 'contact-us'}).catch(() => {})" class="header-link no-wrap"
                style="margin-right:140px">Contact Us
        </button>
        <template v-if="$currentUser.token">
          <div class="no-wrap mr-6 welcome">
            Welcome, {{ $currentUser.first_name }}!
          </div>
          <button @click="logout" class="header-link">
            <v-row class="flex-nowrap" align="center" dense>
              <v-col>
                <v-img src="@/assets/icon-logout.svg" class="logout" height="28px" contain/>
              </v-col>
              <v-col class="no-wrap">Log Out</v-col>
            </v-row>
          </button>
        </template>
        <template v-else>
          <button @click="login" class="header-link">
            <v-row class="flex-nowrap" align="center" dense>
              <v-col>
                <v-img src="@/assets/icon-login.svg" class="logout" height="28px" contain/>
              </v-col>
              <v-col class="no-wrap">Log In</v-col>
            </v-row>
          </button>
        </template>
      </div>
    </v-row>
    <v-row class="pa-0 horizontal-bar"/>
  </v-container>
</template>

<script>
  import PageNavigation from "@/components/PageNavigation";

  export default {
    components: {PageNavigation},
    methods: {
      login: function () {
        this.$router.push({
          name: 'login',
          params: {
            routePath: this.$route.path,
            routeParams: {...this.$route.params},
            routeQuery: {...this.$route.query}
          }
        });
      },
      logout: function () {
        this.$currentUser.logout();
        this.$router.push({name: 'home'}).catch(() => {
        });
      }
    }
  }
</script>

<style scoped>
  .no-wrap {
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  button.header-link {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #45B98A;
    text-transform: uppercase;
    border-radius: 2px;
    padding: 5px 8px;
  }

  .header-link:hover {
    background-color: rgba(69, 185, 138, 0.2);
  }

  .horizontal-bar {
    background-color: #E0DFDE;
    height: 4px;
    padding: 0;
    margin: 7px 0 0 0;
    opacity: 0.5;
  }

  .welcome {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

</style>

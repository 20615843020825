<template>
  <v-container fluid class="pa-0 mt-16" style="display: inline-block;">
    <h2>Revision History</h2>
    <table>

      <thead>
        <tr>
          <th>Effective Dates</th>
          <th>Summary of Revision</th>
          <th>Previous Versions</th>
        </tr>
      </thead>

      <tbody v-if="revisionHistory.length !== 0">
        <tr v-for="version in revisionHistory" :key="version.id">
          <td>{{ formattedEffectiveDate(version.effective_start_date, version.effective_end_date) }}</td>
          <td>{{ version.guideline_revision_summary }}</td>
          <td align="center">
            <v-btn
              v-if="version.id !== version.parent_guideline"
              color="#0B578B"
              class="white--text font-weight-bold text-capitalize"
              width="200"
              target="_blank"
              :to="{name: 'guidelines-previous-versions', params: {id: version.id}}"
            >View</v-btn>
            <span v-else>
              Effective
            </span>
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr v-for="n in 3" :key="n">
          <td><v-skeleton-loader type="text"/></td>
          <td><v-skeleton-loader type="text@3"/></td>
          <td><v-skeleton-loader type="text"/></td>
        </tr>
      </tbody>

    </table>

  </v-container>
</template>

<script>

  import { guidelinesStore } from '@/store/guidelines';
  import { DateTime } from 'luxon';

  export default {
    props: {
      guidelineId: {
        type: Number,
        default: null
      },
    },
    data() {
      return {
        revisionHistory: []
      }
    },
    methods: {
      getRevisionHistory() {
        guidelinesStore()
        .getRevisionHistory(this.guidelineId)
        .then((response) => this.getCurrentGuideline(response.data));
      },
      async getCurrentGuideline(revisionHistory) {

        const guideline = await guidelinesStore().get(this.guidelineId);

        revisionHistory.unshift({
          id: this.guidelineId,
          title: guideline.title,
          parent_guideline: this.guidelineId,
          effective_start_date: guideline.effective_start_date,
          effective_end_date: guideline.effective_end_date,
          guideline_revision_summary: guideline.guideline_revision_summary,
        });

        this.revisionHistory = revisionHistory;
      },
      formattedEffectiveDate(startDate, endDate) {

        if (!startDate) {
          return '';
        }

        if (startDate && !endDate) {
          return this.getFormattedDate(startDate) + ' - ' + 'N/A';
        }

        return this.getFormattedDate(startDate) + ' - ' + this.getFormattedDate(endDate);
      },
      getFormattedDate(date) {
        return DateTime.fromISO(date).toFormat('LL/dd/yyyy');
      },
    },
    mounted () {
      if (this.guidelineId) {
        this.getRevisionHistory();
      }
    },
    watch: {
      guidelineId(newValue) {
        if (newValue) {
          this.revisionHistory = [];
          this.getRevisionHistory();
        }
      }
    },
  }
</script>

<style scoped>

h2 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

th {
  border-left: 1pt solid white;
}

tbody > tr {
  border-bottom: 1pt solid #7F8080;
  font-weight: 400;
}

th:nth-child(odd), td:nth-child(odd) {
  width: 20%;
}

th {
  background-color: #7F8080;
  color: white;
}

th, td {
  padding: 12px;
}

</style>
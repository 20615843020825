<template>
  <v-container fluid class="form-container rounded-lg elevation-10 py-10">
    <v-row>
      <v-container fluid class="mx-5">
        <validation-observer
            ref="observer"
            v-slot="{ validate }"
        >
          <v-form @submit.prevent="validate().then(hubspotSubmit)" class="form">
            <v-row dense>
              <v-col cols="6">
                <validation-provider
                    v-slot="{ errors }"
                    vid="firstname"
                    name="First Name"
                    rules="required"
                >
                  <v-text-field
                      v-model="firstname"
                      :error-messages="errors"
                      label="First Name"
                      outlined
                      autocomplete="given-name"
                      class="required"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                    v-slot="{ errors }"
                    vid="lastname"
                    name="Last Name"
                    rules="required"
                >
                  <v-text-field
                      v-model="lastname"
                      :error-messages="errors"
                      label="Last Name"
                      outlined
                      autocomplete="family-name"
                      class="required"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="email"
                    name="Email"
                    rules="required|email"
                >
                  <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      label="Business Email"
                      outlined
                      autocomplete="email"
                      class="required"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="phone"
                    name="phone"
                >
                  <v-text-field
                      v-model="phone"
                      :error-messages="errors"
                      label="Phone Number"
                      outlined
                      autocomplete="tel"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="company"
                    name="Company"
                    rules="required"
                >
                  <v-text-field
                      v-model="company"
                      :error-messages="errors"
                      label="Company Name"
                      outlined
                      autocomplete="organization"
                      class="required"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="jobtitle"
                    name="jobtitle"
                >
                  <v-text-field
                      v-model="jobtitle"
                      :error-messages="errors"
                      label="Job Title"
                      outlined
                      autocomplete="organization-title"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="department"
                    name="department"
                >
                  <v-text-field
                      v-model="department"
                      :error-messages="errors"
                      label="Department"
                      outlined hide-details=true
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <span>Business Segment</span>
                <v-checkbox
                    hide-details=true dense
                    name="business_segment"
                    v-model="business_segment"
                    label="Medicare"
                    value="Medicare"
                    color="#004568"
                ></v-checkbox>
                <v-checkbox
                    hide-details=true dense
                    name="business_segment"
                    v-model="business_segment"
                    label="Medicaid"
                    value="Medicaid"
                    color="#004568"
                ></v-checkbox>
                <v-checkbox
                    hide-details=true dense
                    name="business_segment"
                    v-model="business_segment"
                    label="Commercial"
                    value="Commercial"
                    color="#004568"
                ></v-checkbox>
                <v-checkbox
                    hide-details=true dense
                    name="business_segment"
                    v-model="business_segment"
                    label="Other"
                    value="Other"
                    color="#004568"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="ig_inquiry_type"
                    name="ig_inquiry_type"
                >
                  <v-select
                      name="ig_inquiry_type"
                      v-model="ig_inquiry_type"
                      :items="inquiryTypeOptions"
                      :error-messages="errors"
                      label="Inquiry Type"
                      outlined hide-details=true
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="message"
                    name="message"
                >
                  <v-textarea
                      v-model="message"
                      :error-messages="errors"
                      label="Write in a message" background-color="white"
                      outlined hide-details=true
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn type="submit" block color="#004568" class="white--text py-6">SUBMIT</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
  import {extend} from 'vee-validate';

  extend('required', {
    validate: value => !!value,
    message: '{_field_} is required'
  });

  export default {
    data() {
      return {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        company: null,
        jobtitle: null,
        department: null,
        business_segment: [],
        ig_inquiry_type: null,
        inquiryTypeOptions: ['View an InformedGENOMICS demo', 'Receive InformedGENOMICS pricing information', 'Sign up for InformedGENOMICS subscription', 'Other'],
        message: null,
        formFields: [
          "firstname",
          "lastname",
          "email",
          "phone",
          "company",
          "jobtitle",
          "department",
          "business_segment",
          "email",
          "phone",
          "ig_inquiry_type",
          "message"
        ]
      }
    },
    methods: {
      hubspotSubmit: function (valid) {
        if (!valid) {
          return false;
        }
        let data = {fields: []};
        this.formFields.forEach((field) => {
          data.fields.push({
            name: field,
            value: Array.isArray(this[field]) ? this[field].join(',') : this[field] ?? ''
          });
        });

        this.$ctaModal.register(data).then(() => this.$emit('complete'));
      }
    },
  }
</script>

<style scoped>

  .form-container {
    background-color: #FFFFFF;
    color: #333333;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  h2 {
    font-weight: 400;
    font-size: 38px;
    line-height: 42px;
  }

  :deep(.v-input) .v-label.v-label--active {
    color: #888888;
  }

  :deep(.v-input) {
    color: #000000;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
  }

  :deep(.required label::after) {
    content: "*";
    color: #B8012D;
  }

  :deep(.v-input.v-input--is-focused.primary--text) {
    color: #45B98A !important;
    caret-color: #45B98A !important;
  }

  :deep(.v-input) .v-label.v-label--active.primary--text {
    color: #888888 !important;
    caret-color: #888888 !important;
  }

  :deep(.v-input) .v-input__slot {
    min-height: 64px;
  }

  :deep(.v-input.v-input--dense) .v-input__slot {
    min-height: inherit;
  }

  :deep(.v-input) .v-select__selection {
    overflow: visible;
  }


</style>

<style>
  div.v-overlay__content {
    height: 100%;
  }
</style>
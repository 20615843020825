import {currentUserStore} from "@/store/current-user";
import {alertsStore} from "@/store/alerts";
import router from '@/router';

class BaseApiService {
    baseUrl = "/wp-json/wp/v2";
    tokenUrl = "/wp-json/jwt-auth/v1";

    constructor() {
        this.host = window?.env?.api_url ?? '';
    }

    handleErrors(err) {
        console.log({message: "Error", err});
    }

    async fetch(url, config = {}) {
        try {
            if (this.user().token) {
                config.headers = config.headers ?? new Headers();
                config.headers.append('Authorization', `Bearer ${this.user().token}`);
            }
            const response = await fetch(`${this.host}${url}`, config)
                .then(resp => {
                    if (!resp.ok) {
                        switch (resp.status) {
                            case 401:
                                this.unauthorized();
                                throw new Error("Unauthorized");
                            default:
                                throw new Error(`Invalid response code: ${resp.status}`);

                        }
                    }

                    return resp;
                })

            return await response.json();
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async get(url, query) {
        const queryString = typeof query === 'object' && Object.keys(query).length ? '?' + new URLSearchParams(query).toString() : '';
        return await this.fetch(url + queryString);
    }

    async post(url, data = {}) {
        return await this.fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    async put(url, data = {}) {
        return await this.fetch(url, {
            method: "PUT",
            body: JSON.stringify(data)
        });
    }

    async delete(url) {
        await fetch(url, {
            method: "DELETE"
        });
        return true;
    }

    async token(username, password) {
        return await this.post(`${this.tokenUrl}/token`, {
            username: username,
            password: password,
        });
    }

    /**
     * Use this to get the user store. Can't put in constructor because Pinia not yet loaded
     */
    user() {
        if (this.currentUser) {
            return this.currentUser;
        }
        this.currentUser = currentUserStore();
        return this.currentUser;
    }

    /**
     * Use this to get the alerts store. Can't put in constructor because Pinia not yet loaded
     */
    alerts() {
        if (this.alertStore) {
            return this.alertStore
        }
        this.alertStore = alertsStore();
        return this.alertStore;
    }

    router() {
        if (this.routerObject) {
            return this.routerObject;
        }
        this.routerObject = router;
        return this.routerObject;
    }

    formattedUrl(url) {
        return `${this.host}${url}`;
    }

    unauthorized() {
        const user = this.user();
        const router = this.router();
        const alerts = this.alerts();

        router.push({path: '/'}).catch(() => {
        });
        alerts.warning('Session expired. Please login to continue.');
        user.logout();
    }

}

class Paginator {

    current_page = 1;   // current page
    last_page = null;
    per_page = 20       // items per page
    api = null;
    endpoint = '';      // endpoint
    total = null;       // total items in result set
    pages = null;       // total pages in result set
    items = [];         // retrieved items
    filters = [];       // TODO: Filtering

    constructor(api, options = {}) {
        this.api = api;
        this.per_page = options?.per_page ?? this.per_page;
        this.current_page = options?.page ?? this.current_page;
        this.endpoint = options?.endpoint ?? this.endpoint;
        this.endpoint.replace(/^\/+/, '');      // remove leading /'s from given endpoint
    }

    async next() {
        this.current_page++;
        if (!this.validPage(this.current_page)) {
            return this;
        }
        return this.fromResponse(await this.call());
    }

    async previous() {
        this.current_page--;
        if (!this.validPage(this.current_page)) {
            return this;
        }
        return this.fromResponse(await this.call());
    }

    async get(page = null) {
        page = page ?? this.current_page ?? 1;
        return this.fromResponse(await this.call(page));
    }

    async call(page = null, per_page = null) {
        return await this.api.get(this.url(), {
            page: page ?? this.current_page,
            per_page: per_page ?? this.per_page,
        });
    }

    fromResponse(response) {
        this.items = response.data;
        this.total = response.total;
        this.current_page = response.current_page;
        this.last_page = response.last_page;

        return this;
    }

    url() {
        if (typeof this.api.url === 'function') {
            return this.api.url() + (this.endpoint ? '/' + this.endpoint : '');
        }

        throw "API has no resource URL";
    }

    validPage(page) {
        return !(page < 1 || (this.last_page && page > this.last_page));
    }
}

export class WordpressApiService extends BaseApiService {

    constructor(resource) {
        super();
        this.resource = resource;
    }

    async create(data, action = '') {
        return await this.post(this.url(null, action), data);
    }

    async read(id = '', action = '', params = {}) {
        return await this.get(this.url(id, action), params);
    }

    async update(id, data, action = '') {
        if (!id) {
            return false;
        }
        return await this.put(this.url(id, action), data);
    }

    async delete(id) {
        return await this.delete(`${this.baseUrl}/${this.resource}/${id}`);
    }

    async list(params) {
        return await this.read(null, null, params);
    }

    async listRelated(id, related, params = {}) {
        return await this.read(id, related, params);
    }

    async paginate(options = {}) {
        const paginator = new Paginator(this, options);
        await paginator.get();
        return paginator;
    }

    url(id, action) {
        let url = `${this.baseUrl}/${this.resource}`;
        url += id ? `/${id}` : '';
        url += action ? `/${action}` : '';
        return url;
    }

}

export class WordpressCustomApiService extends WordpressApiService {

    constructor(baseUrl, resource) {
        super(resource);
        this.baseUrl = baseUrl;
    }
}

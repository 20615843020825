import {defineStore} from 'pinia'

export const searchTermsGuidelinesStore = defineStore('search-terms-guidelines', {
    state: () => ({
        items: [],
        searched: null,
        selected: null,
    }),
    actions: {
        async list(query) {
            try {
                const result = await this.$wp.searchTerms.list(query ? {q: query} : null);
                this.items = Array.isArray(result) ? result : [];
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async get(name) {
            return await this.$wp.searchTerms.read(null, 'by-name', {q: name});
        },
        reset() {
            this.items = [];
            this.searched = null;
            this.selected = null;
        }
    },
})


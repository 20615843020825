import * as FullStory from '@fullstory/browser';

/**
 * full story initialization
 */
FullStory.init({
    orgId: window?.env?.fs_org_id,
    devMode: ['dev', 'qa', 'demo'].includes(window?.env?.ig_environment)
});

export default FullStory;
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                error: '#B8012D',
            },
            dark: {
                error: '#B8012D'
            }
        },
    },
})

export default vuetify
import { render, staticRenderFns } from "./PostSearchBar.vue?vue&type=template&id=6dfdb762&scoped=true&"
import script from "./PostSearchBar.vue?vue&type=script&lang=js&"
export * from "./PostSearchBar.vue?vue&type=script&lang=js&"
import style0 from "./PostSearchBar.vue?vue&type=style&index=0&id=6dfdb762&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dfdb762",
  null
  
)

export default component.exports
class BaseApiService {
    baseUrl = "//api.hsforms.com/submissions/v3/integration";

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        console.log({message: "Errors is handled here", err});
    }

    async fetch(url, config = {}) {
        try {
            const response = await fetch(`${this.baseUrl}${url}`, config);
            return await response.json();
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async post(url, data = {}) {
        return await this.fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}

export class HubspotApiService extends BaseApiService {
    portalId = "5192453";

    async submitForm(formId, data) {
        return await this.post(`/submit/${this.portalId}/${formId}`, data);
    }
}


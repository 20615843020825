<template>
  <v-app>
    <v-main>
      <page-alerts/>
      <page-end-user-license-agreement/>
      <cta-modal v-if="!$currentUser.loggedIn()"/>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  import PageAlerts from "@/components/PageAlerts";
  import CtaModal from "@/components/call-to-action/CtaModal";
  import PageEndUserLicenseAgreement from "@/components/PageEndUserLicenseAgreement";

  export default {
    name: 'App',
    components: {PageEndUserLicenseAgreement, CtaModal, PageAlerts},
    mounted() {
      if (this.$currentUser.registered_date) {
        this.setGtagUserProperty('registered_date', this.$currentUser.registered_date);
      }
      if (this.$currentUser.email) {
        this.setGtagUserProperty('email_domain', this.$currentUser.emailDomain);
      }
      this.setGtagUserProperty('logged_in', this.$currentUser.loggedIn() ? 'yes' : 'no');

      // fullstory identification
      if (this.$currentUser.loggedIn()) {
        this.$currentUser.identify();
      }
    },
    watch: {
      "$currentUser.registered_date": function (value) {
        if (value) {
          this.setGtagUserProperty('registered_date', value);
        }
      },
      "$currentUser.email": function (value) {
        if (value) {
          this.setGtagUserProperty('email_domain', this.$currentUser.emailDomain);
        }
      },
      "$currentUser.token": function () {
          this.setGtagUserProperty('logged_in', this.$currentUser.loggedIn() ? 'yes' : 'no');

          // reload every page after a login state change, except the login page itself
          if (this.getRouterName() !== 'login') {
            this.$router.go();
          }

      }
    },
    methods: {
      setGtagUserProperty: function(property, value) {
        let config = {
          user_properties: {}
        };
        config.user_properties[property] = value;
        this.$gtag.set(config);
      },
      getRouterName: function() {
        return this.$router?.currentRoute?.name;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');

  $body-font-family: 'Work Sans';

  main {
    background: transparent;
  }

  .v-application {
    font-family: $body-font-family, sans-serif !important;
  }
</style>

<style>
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
</style>

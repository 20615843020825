<template>
  <v-container fluid>
    <v-row align="center">
      <div class="per-page-label mr-2">
        <span>Results Per Page:</span>
      </div>
      <div class="pa-0">
        <v-select
          :items="options"
          :value="itemsPerPage.toString()"
          :color="'#EDA341'"
          :item-color="'orange lighten-1'"
          @change="changePerPage"
          class="per-page-select"
          dense outlined
        ></v-select>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        options: ['10', '25', '50', '100', {text: 'View All', value: '-1'}]
      }
    },
    props: {
      itemsPerPage: {
        type: Number,
        default: 10
      },
    },
    methods: {
      changePerPage(value) {
        this.$emit('changePerPage', {per_page: value});
      }
    },
  }
</script>

<style scoped>
.per-page-label {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.per-page-select {
  height: 40px;
  width: 122px;
}
</style>
<template>
  <v-container fluid class="pa-0">
    <PostContainer :post="post" :section="section"/>
    <CtaSignUp v-if="!$currentUser.loggedIn()" class="mb-8 px-6"/>
  </v-container>
</template>

<script>
  import PostContainer from "@/components/posts/PostContainer";
  import CtaSignUp from "@/components/call-to-action/CtaSignUp";

  export default {
    props: {
      section: {
        type: String,
        required: true,
      },
      post: {
        default: {},
        required: true
      }
    },
    components: {
      PostContainer,
       CtaSignUp
    },
  }
</script>

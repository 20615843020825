<template>
  <div>
    <VRow class="ma-0 mb-4">
      <h2>The full content is only available to subscribers.</h2>
    </VRow>
    <CtaGainAccess class="px-0 ml-1"/>
  </div>
</template>

<script>
  import CtaGainAccess from "@/components/call-to-action/CtaGainAccess";

  export default {
    components: {
      CtaGainAccess,
    },
  }
</script>

<style scoped>
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #333333;
  }
</style>
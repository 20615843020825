import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import '@/assets/css/app.css'
import vueDebounce from 'vue-debounce'
import VueGtag from "vue-gtag";
import pinia from '@/store'
import {PiniaVuePlugin} from 'pinia'
import "@/plugins/mixins"
import {setInteractionMode} from "vee-validate"
import {ValidationObserver, ValidationProvider, extend} from "vee-validate"
import {required, email} from "vee-validate/dist/rules";
import VueCookies from 'vue-cookies';
import FullStory from '@/fullstory';

Vue.prototype.$FullStory = FullStory;

extend('required', required)
extend('email', email)

setInteractionMode('eager')

Vue.prototype.$env = window?.env;

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.use(PiniaVuePlugin)

Vue.use(vueDebounce)
Vue.config.productionTip = false

Vue.use(VueGtag, {
    config: {
        id: window?.env?.ga4_id
    }
}, router);

Vue.use(VueCookies, {expires: '90s'});

new Vue({
    vuetify,
    router,
    pinia,
    render: h => h(App)
}).$mount('#app')

// nothing to see.

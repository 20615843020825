<template>
  <v-container class="pa-0 mb-6">
    <h2 :class="{active: activeSection === title}">{{ title }}</h2>

    <slot>
      <ul class="mt-2">
        <li v-for="(item, index) in items" :key="`index_link_${index}`" class="mt-2">
          
          <v-tooltip top content-class="ig-grey tooltip-top" color="#333333">
            <template v-slot:activator="{ on }">
              <button @click="item.action" :class="{active: item.post === activePost}" v-on="on">
                {{ item.post.title }}
              </button>
            </template>
            <span>{{ item.post.title }}</span>
          </v-tooltip>

        </li>
      </ul>
    </slot>

    <template v-if="divider">
      <hr class="mt-6"/>
    </template>

  </v-container>
</template>

<script>

  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      activePost: {
        type: Object,
      },
      activeSection: {
        type: String,
      },
      items: {
        type: Array,
        default: () => []
      },
      divider: {
        type: Boolean,
        default: false
      }

    },
  }
</script>

<style scoped>
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }

  button {
    font-weight: 400;
    text-decoration: underline;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }

  .active {
    color: #004568;
  }

  hr {
    width: 100%;
    opacity: 0.25;
    border: 1px solid #888888;
  }
</style>
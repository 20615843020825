<template>
  <v-container class="terms-container rounded">
    <v-container class="my-5 mx-14 terms-inner">
      <v-row class="justify-center">
        <h2>End User License Agreement</h2>
      </v-row>
      <v-row class="mt-6">
        <p>
          Welcome to InformedGENOMICS™.
          In order to use our service, you must read and accept our <a href="/end-user-license-agreement"
                                                                       target="_blank">
          End User License Agreement.</a>
        </p>
      </v-row>
      <v-row>
        <v-container fluid>
          <validation-observer
              ref="observer"
          >
            <v-form @submit.prevent="accept">
              <v-row>
                <validation-provider
                    v-slot="{ errors }"
                    vid="end-user-license-agreement"
                    name="end-user-license-agreement"
                    rules="required"
                >
                  <v-checkbox
                      label="I agree with the End User License Agreement"
                      color="#004568"
                      dense hide-details
                      v-model="endUserLicenseAgreement"
                      :error-messages="errors"
                      value="true"
                  ></v-checkbox>
                </validation-provider>
              </v-row>
              <v-row class="mt-10 button-row">
                <button type="submit" :disabled="!formValid">Accept</button>
                <button @click.prevent="decline" class="outlined">Decline</button>
              </v-row>
            </v-form>
          </validation-observer>
        </v-container>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    data: function () {
      return {
        endUserLicenseAgreement: false,
        eulaUrl: '/end-user-license-agreement',
        signinUrl: '/login',
      }
    },
    methods: {
      decline: function () {
        this.$currentUser.logout();
        this.$router.push({path: '/'})
            .then(() => this.declineWarning())
            .catch(() => this.declineWarning());
      },
      accept: function () {
        this.$refs.observer.validate().then(res => {
          if (res) {
            this.$currentUser.acceptEndUserLicenseAgreement();
          }
        })
      },
      declineWarning: function () {
        const message = `In order to access this site’s full content, please <a href="${this.signinUrl}">Log In</a> and accept the <a href="${this.eulaUrl}">End User License Agreement</a>.`;
        this.$alerts.warning(message);
      }
    },
    computed: {
      formValid: function () {
        return this.endUserLicenseAgreement;
      }
    }
  }
</script>

<style scoped>
  .terms-container {
    background-color: white;
  }

  .terms-inner {
    width: 420px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  .button-row {
    gap: 20px;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  button {
    background-color: #004568;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    flex-grow: 1;
    padding: 10px 0;
    border-radius: 2px;
  }

  button:disabled {
    background-color: #CCCCCC;
  }

  button:hover:not(:disabled) {
    background-color: rgba(11, 87, 139, 0.75);
  }

  button.outlined {
    background-color: #FFFFFF;
    border: 1px solid #004568;
    color: #004568;
  }

  button.outlined:hover {
    background-color: #004568;
    color: #FFFFFF;
  }
</style>
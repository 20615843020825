import {defineStore} from 'pinia'

export const caseProcessingChangeSummary = defineStore('case-processing-change-summary', {
    actions: {
        async search(params) {
            try {
                return await this.$wp.caseProcessing.listRelated(params.id ?? params.t, 'changes', {
                    page: params.page ?? 1,
                    per_page: params.per_page ?? 10
                });
            } catch (error) {
                console.log(error);
                return error;
            }
        },
    },
})

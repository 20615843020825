<template>
  <auth-page>
    <reset-password-form
        v-if="tokenValid"
    />
    <invalid-token
        v-else-if="tokenChecked"
    />
  </auth-page>
</template>

<script>
  import AuthPage from "@/components/auth/AuthPage";
  import ResetPasswordForm from "@/components/auth/ResetPasswordForm";
  import InvalidToken from "@/components/auth/InvalidToken";
  import {passwordResetStore} from "@/store/password-reset";

  export default {
    components: {AuthPage, ResetPasswordForm, InvalidToken},
    data() {
      return {
        tokenChecked: false,
        tokenValid: false,
      }
    },
    props: {
      token: {
        type: String,
        default: null,
      },
    },
    computed: {
      passwordReset: () => passwordResetStore()
    },
    methods: {
      async validateToken(token) {
        this.tokenValid = await this.passwordReset.validateToken(token).catch(() => {
          this.tokenChecked = true;
          this.tokenValid = false;
        });
        this.tokenChecked = true;
      }
    },
    beforeMount() {
      this.passwordReset.token = this.$route.query.token;
      this.passwordReset.email = this.$route.query.email;

      this.validateToken(this.token);
    }
  }
</script>

<template>
  <v-container fluid class="mt-12">
    <v-row align="center" justify="center" no-gutters class="mb-12" style="gap:20px">
      <div class="no-results">No results found</div>
    </v-row>
    <v-row v-if="ring === true" class="ring"/>
    <slot></slot>
  </v-container>
</template>

<script>
  export default {
    props: {
      ring: {
        type: Boolean,
        default: false
      },
    },
  }
</script>

<style scoped>
  .ring {
    background-image: url('@/assets/shattered-ring.svg');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: auto 500px;
    margin-right: -100px;
    margin-top: -200px;
    min-height: 540px;
    color: #AAAAAA;
  }

  .no-results {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #7F8080;
    flex: none;
  }

</style>

<template>
  <page-with-footer>
    <template v-slot:content>
      <page-header class="default-gutter mb-6"/>
      <page-breadcrumbs/>
      <v-container fluid class="default-gutter" >
        <cpt-code-scope-of-inclusion />
      </v-container>
    </template>
  </page-with-footer>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import PageWithFooter from "@/components/PageWithFooter";
  import PageBreadcrumbs from "@/components/PageBreadcrumbs";
  import CptCodeScopeOfInclusion from "@/components/documents/CptCodeScopeOfInclusion";

  export default {
    components: {
      CptCodeScopeOfInclusion,
      PageBreadcrumbs,
      PageWithFooter,
      PageHeader,
    },
    mounted() {
      this.$breadcrumbs.clear();
      this.$breadcrumbs.pushRoute({path: '/'}, 'Home', false);
      this.$breadcrumbs.pushRoute({path: '/cpt-code-scope-of-inclusion'}, 'CPT® Code Scope of Inclusion', true);
    }
  }
</script>

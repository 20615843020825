<template>

  <PageWithFooter>
    <template v-slot:content>
      <PageHeader class="default-gutter mb-6"/>
      <PageBreadcrumbs/>
      <v-container fluid class="mt-16">
        <v-row align="center" justify="center" no-gutters class="mb-12" style="gap:20px">
          <div class="page-not-found-text">404: Page not found</div>
        </v-row>
        <v-row class="ring"/>
      </v-container>
    </template>
  </PageWithFooter>

</template>

<script>

  import PageWithFooter from '@/components/PageWithFooter';
  import PageHeader from '@/components/PageHeader';
  import PageBreadcrumbs from '@/components/PageBreadcrumbs';

  export default {
    beforeMount: function () {
      this.setBreadcrumbs();
    },
    methods: {
      setBreadcrumbs: function () {
        this.$breadcrumbs.clear();
        this.$breadcrumbs.pushRoute({path: '/'}, 'Home');
        this.$breadcrumbs.pushRoute({path: '/page-not-found'}, 'Page not found', true);
      },
    },
    components: {
      PageWithFooter,
      PageHeader,
      PageBreadcrumbs,
    },
  }
</script>

<style scoped>
  .page-not-found-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #7F8080;
    flex: none;
  }
.ring {
  background-image: url('@/assets/shattered-ring.svg');
  background-position: right top;
  background-repeat: no-repeat;
  background-size: auto 500px;
  margin-right: -100px;
  margin-top: -100px;
  min-height: 540px;
  color: #AAAAAA;
}
</style>
<template>
  <v-row v-if="$breadcrumbs.items.length" class="breadcrumbs">
    <v-breadcrumbs
        class="default-gutter"
        :items="$breadcrumbs.items"
        divider=">"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
            :to="item.to"
            class="text-subtitle-2 crumb-item"
            :disabled="!!item.disabled"
            exact
        >
          <span class="breadcrumb-item" :class="{disabled: !!item.disabled}">{{ item.text }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-row>
</template>

<script>

  export default {}

</script>

<style scoped>
  .breadcrumbs {
    background-color: #E0DFDE;
    padding: 10px 0;
    margin: 0;
  }

  .breadcrumb-item  {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #333333;

    max-width:100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .breadcrumb-item.disabled {
    font-weight: bold;
  }

  :deep(.v-breadcrumbs li:last-child), :deep(.v-breadcrumbs li:last-child a) {
    min-width:0;
  }

  :deep(.v-breadcrumbs) {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }
</style>

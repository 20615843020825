import {defineStore} from 'pinia'

export const clinicalContextsStore = defineStore('clinical-contexts', {
    state: () => ({
        items: [],
        postTypeMap: {
            background: 'backgrounds',
            references: 'references',
            'case-processing': 'caseProcessing',
            guideline: 'guidelines'
        }
    }),
    actions: {
        async get (id) {
            if (this.items[`context_${id}`]) {
                return this.items[`context_${id}`]
            }

            return this.read(id);
        },
        async read(id) {
            const posts = await this.$wp.contexts.read(id);

            if (!posts || posts.length === 0) {
                return {};
            }

            this.items[`context_${id}`] = {
                backgrounds: [],
                references: [],
                caseProcessing: [],
                guidelines: [],
                cptCodes: []
            }

            posts.forEach(post => {
                this.items[`context_${id}`][this.postTypeMap[post.type]].push({
                    id: post.id,
                    type: post.type,
                    title: post.title,
                    content: post.content,
                    release_date: post.release_date,
                    preview: post.preview
                });
            });

            return this.items[`context_${id}`];
        },
    },
})

<template>

  <PageWithFooter>
    <template v-slot:content>
      <PageHeader class="default-gutter mb-6"/>
      <HelpCenterBreadcrumbs/>
      <v-container fluid class="default-gutter my-12">
        <HelpCenterContentHeader/>
        <HelpCenterContent/>
        <HelpCenterAdditionalResources/>
      </v-container>
    </template>
  </PageWithFooter>

</template>

<script>

  import PageWithFooter from '@/components/PageWithFooter.vue';
  import PageHeader from '@/components/PageHeader.vue';
  import HelpCenterBreadcrumbs from '@/components/help-center/HelpCenterBreadcrumbs.vue';

  import HelpCenterContentHeader from '@/components/help-center/HelpCenterContentHeader.vue';
  import HelpCenterContent from '@/components/help-center/HelpCenterContent.vue';
  import HelpCenterAdditionalResources from '@/components/help-center/HelpCenterAdditionalResources.vue';

  export default {
    beforeMount: function () {
      if (!this.$currentUser.loggedIn()) {
        return this.$router.push({name: 'login', query: {'redirect': 'back'}}).then(() => {
          this.$alerts.error('Please login to access help center page.', {timeout: 5000})
        });
      }
    },
    components: {
      PageWithFooter,
      PageHeader,
      HelpCenterBreadcrumbs,
      HelpCenterContentHeader,
      HelpCenterContent,
      HelpCenterAdditionalResources,
    },
  }
</script>
<template>
  <page-with-footer>
    <template v-slot:content>
      <page-header class="default-gutter"/>
      <global-search-bar class="default-gutter mt-6 mb-4" @selected="selected" :initial-term="terms.selected"/>
      <page-breadcrumbs class="mb-8"/>
      <context-container class="default-gutter"/>
    </template>
  </page-with-footer>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import GlobalSearchBar from "@/components/search/global/GlobalSearchBar";
  import ContextContainer from "@/components/context/ContextContainer";
  import PageWithFooter from "@/components/PageWithFooter";
  import PageBreadcrumbs from "@/components/PageBreadcrumbs";
  import {searchTermsStore} from "@/store/search-terms";

  export default {
    components: {
      PageBreadcrumbs,
      PageWithFooter,
      PageHeader,
      GlobalSearchBar,
      ContextContainer
    },
    props: {
      item: {
        default: null,
        required: false
      }
    },
    computed: {
      terms: () => searchTermsStore()
    },
    methods: {
      selected: function (item) {
        if (item === null) {
          return;
        }

        this.$router.push({
          path: '/search',
          query: {
            q: item?.name,
            t: item?.id
          }
        }).catch(() => {
        });

      }
    },
    beforeMount() {
      if (this.$route.query?.t && this.$route.query?.q) {
        this.terms.selected = {
          name: this.$route.query.q,
          id: this.$route.query.t
        };
      }

      this.$breadcrumbs.clear();
      this.$breadcrumbs.pushRoute({path: '/'}, 'Home', false);
      if (this.terms.selected?.name && this.terms.selected?.id) {
        this.$breadcrumbs.pushRoute({
          path: '/search',
          query: {q: this.terms.selected.name, t: this.terms.selected.id}
        }, this.terms.selected.name, false);
      }

    }
  }
</script>

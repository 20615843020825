<template>
  <v-container fluid class="pa-0">
    <PostContent :post="post">
      <template v-slot:append-end-content>
        <PermissionValidatorContainer :has-permission="$currentUser.loggedIn()">
          <GuidelineRevisionHistoryTable :guideline-id="post.id"/>
        </PermissionValidatorContainer>
      </template>
    </PostContent>
  </v-container>
</template>

<script>

  import PostContent from '@/components/posts/PostContent';
  import GuidelineRevisionHistoryTable from '@/components/posts/guideline/GuidelineRevisionHistoryTable'
  import PermissionValidatorContainer from '@/components/permissions/PermissionValidatorContainer';

  export default {
    props: {
      post: {
        default: {},
        required: true
      }
    },
    components: {
      PostContent,
      GuidelineRevisionHistoryTable,
      PermissionValidatorContainer
    },
  }
</script>

<style scoped>

</style>
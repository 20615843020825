<template>
  <v-row class="banner mt-10 mb-16">
    <v-col cols="7" class="welcome-text-container">
      <h2>Welcome to InformedGENOMICS™</h2>
      <p>
        The most comprehensive and up-to-date GENOMICS policy guidance—at your fingertips
      </p>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'LandingWelcome',
  }
</script>

<style scoped>

  .banner {
    background-image: url("@/assets/hero-banner.png");
    background-repeat: no-repeat;
    background-position: center right;
    padding-left: 150px;
    min-height: 363px;
    width: 100%;
  }

  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: #004568;
    margin-top: 72px;
    margin-bottom: 32px;
  }

  p {
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    color: #333333;
  }


  img {
    height: 504px;
    z-index: -1;
  }

</style>

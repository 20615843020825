<template>
  <div></div>
</template>

<script>
  export default {
    beforeMount() {
      if (this.$currentUser.loggedIn()) {
        window.location.replace(`${this.$env.api_url}/wp-json/api/v1/uploads/${this.$route.params.file}`);
      } else {
        this.$router.push({name: 'login',
          params: {
            routePath: this.$route.path,
            routeParams: {...this.$route.params},
            routeQuery: {...this.$route.query}
          }
        });
      }
    }
  }
</script>
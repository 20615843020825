import Vue from "vue";
import {$wp} from "@/services/wordpress/wp-api";
import {$hubspot} from "@/services/hubspot/hubspot-api";
import {breadcrumbsStore} from "@/store/breadcrumbs";
import {currentUserStore} from "@/store/current-user";
import {ctaModalStore} from "@/store/cta-modal";
import {alertsStore} from "@/store/alerts";

Vue.mixin({
    computed: {
        $wp: () => $wp,
        $hubspot: () => $hubspot,
        $breadcrumbs: () => breadcrumbsStore(),
        $currentUser: () => currentUserStore(),
        $ctaModal: () => ctaModalStore(),
        $alerts: () => alertsStore(),
        $env: () => window.env
    },
});
<template>
  <v-container fluid class="pa-0 mb-10 cpt-codes-data-table">
    <div class="overflow-x-auto" style="width: 88.33vw;">
      <VDataTable
          :headers="headers"
          :items="codes"
          :items-per-page="itemsPerPage"
          hide-default-footer disable-sort
      >
        <!-- Ugly but don't want to use row level templating to preserve default row level functionality -->
        <template #[`item.code`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
        <template #[`item.genes-or-test`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
        <template #[`item.description`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
        <template #[`item.lab`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
        <template #[`item.coverage-guidelines`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
        <template #[`item.recommendation`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
        <template #[`item.status`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
        <template #[`item.effective-date`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
        <template #[`item.termed-date`]="{value}">
          <td>
            <div v-html="highlight(value, search)"/>
          </td>
        </template>
      </VDataTable>
    </div>
  </v-container>
</template>

<script>

  export default {
    data() {
      return {
        headers: [
          {
            text: 'Code',
            value: 'code',
          },
          {
            text: 'Specific Gene(s) or Test',
            value: 'genes-or-test',
          },
          {
            text: 'Full Description',
            value: 'description',
          },
          {
            text: 'Specific Lab (if applicable)',
            value: 'lab',
          },
          {
            text: 'InformedGENOMICS™ Coverage Guideline(s)',
            value: 'coverage-guidelines',
          },
          {
            text: 'Recommendation',
            value: 'recommendation',
          },
          {
            text: 'Status',
            value: 'status',
          },
          {
            text: 'Effective Date',
            value: 'effective-date',
          },
          {
            text: 'Termed Date',
            value: 'termed-date',
          }
        ],
      }
    },
    props: {
      itemsPerPage: {
        type: Number,
        default: 10
      },
      codes: {
        type: Array,
        default: () => []
      },
      search: {
        type: String,
        default: ''
      }
    },
    methods: {
      highlight: function (text, query) {
        if (!query || query.length > text.length) {
          return text;
        }

        let regex = query.replace(/[-[\]{}()*+?.,\\/^$|]/g, "\\$&");
        regex = regex.replace(/\s+/g, '\\s+');

        return text.replace(new RegExp(`(${regex})`, "ig"), '<span class="datatable-search-highlight">$1</span>');
      },
    }
  }
</script>

<style scoped>
  .cpt-codes-data-table :deep(table) {
    border-collapse: collapse !important;
    border: 2px solid #FFFFFF !important;
  }

  .cpt-codes-data-table :deep(th) {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    text-align: center !important;
    color: #FFFFFF !important;
    border: 2px solid #FFFFFF !important;
    background: #45B98A !important;
    padding: 38px 20px !important;
  }

  .cpt-codes-data-table :deep(th:nth-child(n + 3)) {
    border-bottom: none !important;
  }

  .cpt-codes-data-table :deep(td) {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: center !important;
    white-space: pre-line !important;

    border: 2px solid #FFFFFF !important;
    color: #000000 !important;

    padding: 10px !important;
  }

  .cpt-codes-data-table :deep(tr:nth-child(even)) {
    background: #E0DFDE !important;
  }

  .cpt-codes-data-table :deep(tr:nth-child(odd)) {
    background: #FFFFFF !important;
  }

  .cpt-codes-data-table :deep(tr > td:nth-child(n + 3)) {
    border-top: none !important;
    border-bottom: none !important;
  }

  .cpt-codes-data-table :deep(tr:nth-child(odd) > td:nth-child(n + 4)) {
    border-left: 2px solid #E0DFDE !important;
  }

  .cpt-codes-data-table :deep(tr:nth-child(odd) > td:nth-child(n + 3)) {
    border-right: 2px solid #E0DFDE !important;
  }

  .cpt-codes-data-table :deep(tr:nth-child(odd) > td:nth-child(9)) {
    border-right: inherit !important;
  }

  .cpt-codes-data-table :deep(tr td:first-child), .cpt-codes-data-table :deep(tr td:nth-child(2)) {
    font-weight: 500 !important;
    background: #004568 !important;
    color: #FFFFFF !important;
  }

  .cpt-codes-data-table :deep(tr td:nth-child(3)) {
    text-align: left !important;
  }

  .cpt-codes-data-table :deep(tr td:first-child span.datatable-search-highlight), .cpt-codes-data-table :deep(tr td:nth-child(2) span.datatable-search-highlight) {
    color: #FCFF66 !important;
    background-color: inherit !important;
  }
  .cpt-codes-data-table :deep(span.datatable-search-highlight) {
    background-color: #FCFF66;
  }
</style>

<template>
  <VContainer fluid>
    <VRow dense>
      <span class="search-total-result-description">
        ({{ totalResult }} {{ pluralizedResultWord }})
      </span>
    </VRow>
  </VContainer>
</template>

<script>
  export default {
    props: {
      totalResult: {
        type: Number,
        required: true,
        default: 0
      }
    },
    computed: {
      pluralizedResultWord() {
        return this.totalResult === 1 ? 'result' : 'results';
      }
    }
  }
</script>

<style scoped>
  .search-total-result-description {
    color: #888888;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
</style>
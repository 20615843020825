<template>
  <VContainer fluid class="mb-7">
    <VRow class="mt-0 portal">
      <VCol class="index-width">
        <ContextIndex
            :context="context"
            :active-post="activePost"
            :active-section="activeSection"
            @post-view="handleIndexClick"
        ></ContextIndex>
      </VCol>
      <VCol class="pt-0 pl-0">
        <DocumentContainer :post="activePost" :section="activeSection"/>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
  import ContextIndex from "@/components/context/ContexIndex";
  import DocumentContainer from "@/components/context/document/DocumentContainer";
  import { clinicalContextsStore } from "@/store/clinical-contexts";

  export default {
    data() {
      return {
        context: {},
        activePost: {content: 'Loading...', preview: 'Loading...'},
        activeSection: "Reference Library"
      }
    },
    computed: {
      contexts: () => clinicalContextsStore(),
    },
    components: {
      ContextIndex,
      DocumentContainer
    },
    methods: {
      handleIndexClick: function (data) {
        this.activePost = data.post;
        this.activeSection = data.section;

      }
    },
    watch: {
      "context": function () {
        this.activePost = this.context?.backgrounds[0] ?? {content: 'No Data'};
      }
    },
    beforeMount() {
      this.contexts.get(this.$route.params.id)
          .then(context => {

            if (Object.keys(context).length === 0) {
              return this.$router.replace({name: 'page-not-found'});
            }

            this.context = context;
          })
          .then(() => {
            if (this.context?.backgrounds?.[0]) {
              this.$breadcrumbs.enableLast();
              this.$breadcrumbs.pushRoute({path: '/'}, this.context.backgrounds[0].title, true);
            }
          });
    }
  }
</script>

<style scoped>
  .portal {
    flex-wrap: nowrap;
  }

  .portal > div:nth-child(2) {
    flex-grow: 5;
    flex-shrink: 2;
  }

  .index-width {
    width: 276px;
    padding: 0 10px 0 12px;
  }
</style>

<template>
  <PostSearchHeader>
    <template v-slot:header-title>
      Coverage Guidelines
    </template>
    <template v-slot:header-description>
      Evidence-based coverage guidelines developed by genetics specialists with clinical expertise and research experience.
    </template>
  </PostSearchHeader>
</template>

<script>
  import PostSearchHeader from '@/components/search/posts/PostSearchHeader';

  export default {
    components: {
      PostSearchHeader,
    },
  }
</script>
<template>
  <v-row class="align-end">
    <v-col cols="5" class="pt-0">
      <h2 class="mb-6">Enjoying our content?</h2>
      <span class="cta-info">Click on the button to learn how to get InformedGENOMICS™ for your organization.</span>
    </v-col>
    <v-col cols="4" class="my-auto">
      <v-row class="justify-center">
        <button class="cta cta-gradient mb-4" @click="$ctaModal.show">Gain Access</button>
      </v-row>
      <v-row class="justify-center">
        <span class="cta-login">Already subscribed? <login-link class="cta-login"/></span>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped>

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #004568;
  }

  button.cta {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 23px 108px;
    text-transform: uppercase;
  }

  span.cta-info {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #004568;
  }

  span.cta-login, a.cta-login {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0B578B;
  }

  a.cta-login:hover {
    opacity: 0.75;
  }

</style>
<script>
  import LoginLink from "@/components/router-links/LoginLink";

  export default {
    components: {LoginLink}
  }
</script>
<template>
  <v-container fluid class="py-0">
    <v-row dense>
      <SearchBar
        :store-class="storeClass"
        :placeholder="''"
        :initial-term="initialTerm"
        :limit-words-before-match="8"
        :search-highlight-type="'phrase'"
        @selected="selected"
        :disabled="disabled"
      >
        <template v-slot:label>
          <span class="search-label">Search by <b>CPT® Codes</b>, Genes, Tests</span>
        </template>
      </SearchBar>
    </v-row>
  </v-container>
</template>

<script>
  import { searchCPTCodes } from "@/store/search-cpt-codes";
  import SearchBar from "@/components/search/SearchBar";

  export default {
    data() {
      return {
        storeClass: searchCPTCodes
      }
    },
    props: {
      initialTerm: {
        default: null
      },
      disabled: {
        default: false
      }
    },
    methods: {
      selected: function (item) {
        if (item === null) {
          this.terms.reset();
        }

        this.$gtag.event('view_search_results', {
          search_term_id: item?.id ?? '',
          search_term: item?.name ?? '',
          search_type: 'cpt code'
        });

        this.$emit('search', item);
      },
    },
    computed: {
      terms: () => searchCPTCodes(),
    },
    components: {
      SearchBar
    },
    beforeMount() {
      this.terms.reset();
    }
  }
</script>

<style scoped>
  :deep(.v-input__prepend-outer) {
    margin: auto !important;
  }

  :deep(.search-label) {
    font-weight: 400;
    font-size: 20px;
    color: #888888;
  }

  .search-bar-row {
    margin-left: -4px !important;
  }

</style>
<template>
  <v-container fluid class="mb-7">
    <v-row class="mt-0 default-gutter">
      <v-col class="pt-0 pb-0 scroll-container" v-if="guideline.id">
        <PostContainer section="Coverage Guidelines" :post="guideline"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { guidelinesStore } from "@/store/guidelines";
  import { searchTermsStore } from "@/store/search-terms";

  import PostContainer from "@/components/posts/PostContainer";

  export default {
    data() {
      return {
        guideline: {
          'title': '',
          'content': '',
          'preview': '',
        }
      }
    },
    computed: {
      guidelines: () => guidelinesStore(),
      store: () => searchTermsStore()
    },
    components: {
      PostContainer,
    },
    methods: {
      getGuideline: async function (id) {
        this.guideline = await this.guidelines.get(id);

        // if the guideline is archived, redirect to the archived guideline view
        if (this.guideline?.archived) {
          return this.$router.push({name: 'guidelines-previous-versions', params: {id: this.guideline.id}}).then(() => {
            this.$alerts.warning('This guideline is archived.', {timeout: 5000})
          });
        }

        if (this.guideline === undefined) {
          return this.$router.replace({name: 'page-not-found'});
        }

        this.guideline.type = 'guideline';
        this.$breadcrumbs.clear();
        this.$breadcrumbs.pushRoute({path: '/'}, 'Home');
        if (this.store.displayedItem?.id && this.store.displayedItem?.name) {
          this.$breadcrumbs.pushRoute({
            path: '/search', query: {
              q: this.store.displayedItem.name,
              t: this.store.displayedItem.id
            }
          }, this.store.displayedItem.name, false)
        }
        this.$breadcrumbs.pushRoute({path: '/search'}, this.guideline.title, true);
      }
    },
    beforeMount() {
      this.getGuideline(this.$route.params.id);
    }
  }
</script>

<style scoped>
  .portal {
    height: 100vh;
  }

  .portal > div:nth-child(1) {
    min-width: 300px;
  }

  .portal > div:nth-child(2) {
    flex-grow: 5;
  }

  .scroll-container {
    height: 100%;
  }

  .scroll {
    height: 100%;
    overflow-y: auto;
  }
</style>

<template>
  <v-container>
    <v-row align-content="center">
      <v-col>
        <v-container class="form-container">
          <v-form ref="form" @submit.prevent="sendEmail(email)">
            <v-row class="mb-8">
              <v-col>
                <v-row>
                  <h2>Forgot your password?</h2>
                </v-row>
                <v-row>
                  <span>Reset your password</span>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-text-field
                  class="rounded-sm login"
                  color="#333333"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  label="Email"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <v-btn
                    class="send rounded-sm no-uppercase"
                    :class="{active: buttonActive}"
                    text x-large
                    :disabled="!buttonActive"
                    type="submit"
                >
                  Send Instructions
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {passwordResetStore} from "@/store/password-reset";

  export default {
    data() {
      return {
        email: this.emailInitial,
        rules: {
          required: value => !!value || '',
          email: value => {
            return this.validEmail(value) || 'Invalid e-mail.'
          },
        }
      }
    },
    props: {
      emailInitial: {
        type: String,
        default: null,
      },
    },
    computed: {
      passwordReset: () => passwordResetStore(),
      buttonActive: function () {
        return !!this.email && this.validEmail(this.email);
      }
    },
    methods: {
      validEmail: function (email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
      },
      sendEmail: async function (email) {
        if (!this.$refs.form.validate()) {
          return false;
        }
        this.passwordReset.email = email;
        const response = await this.passwordReset.sendResetEmail(email);
        if (response) {
          this.$emit('sent', email);
        } else {
          // handle error
        }
        return response;
      }
    }
  }
</script>

<style scoped>
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #004568;
  }

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

  .form-container {
    background-color: #FFFFFF;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    margin: 30px auto;
    max-width: 770px;
    padding: 40px 56px;
  }

  .send {
    width: 100%;
    padding: 27px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    background-color: #EEEEEE;
  }

  .send.active {
    background-color: #EDA341;
    color: #FFFFFF;
  }

  .send.active:hover {
    opacity: 0.7;
  }

  .send {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #888888;
  }

  .login {
    color: #333333;
  }

  .login :deep(input) {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
  }

  .login :deep(label) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .no-uppercase {
    text-transform: unset !important;
  }
</style>
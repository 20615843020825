<template>
  <v-container class="index">
    <v-row class="index-title">
      Index
    </v-row>
    <v-row class="py-8 px-6">
      <context-index-section
          :active-section="activeSection"
          :active-post="activePost"
          title="Reference Library"
          :items="backgrounds"
          :divider="true"
      />
      <context-index-section
          :active-section="activeSection"
          :active-post="activePost"
          title="Decision Aids"
          :items="caseProcessing"
          :divider="true"
      />
      <context-index-section
          :active-section="activeSection"
          :active-post="activePost"
          title="Coverage Guidelines"
          :items="guidelines"
          :divider="true"
      />
      <context-index-section
          :active-section="activeSection"
          :active-post="activePost"
          title="CPT® Code Insights"
          :items="guidelines"
      >
        <template v-if="!!$currentUser.loggedIn()">
          <button v-if="cptCodes.items.length" class="cpt-button mt-2" @click="handleCptCodeClick">Check
            table
          </button>
          <template v-else>
            <span class="no-cpts">If searching with a generic Tier 2 code(s), please search using specific terms for gene(s)/condition(s).</span>
          </template>
        </template>
        <span v-else class="restricted">This content is only available to subscribers.</span>
      </context-index-section>
    </v-row>
    <document-cpt-codes
        v-if="showCptCodes"
        :cpt-codes="cptCodes.items"
        @close="showCptCodes = false"
    />
  </v-container>
</template>

<script>

  import DocumentCptCodes from "@/components/context/document/DocumentCptCodes";
  import {cptCodesStore} from "@/store/cpt-codes";
  import ContextIndexSection from "@/components/context/ContextIndexSection";

  export default {
    data() {
      return {
        panel: [0],
        showCptCodes: false,
      }
    },
    props: {
      context: {
        type: Object,
        required: true
      },
      activePost: {
        type: Object,
      },
      activeSection: {
        type: String
      }

    },
    computed: {
      cptCodes: () => cptCodesStore(),
      references: function () {
        let references = {
          title: 'References',
          id: 'references',
          content: '',
          preview: '',
          type: 'references'
        }
        if (Array.isArray(this.context.references)) {
          references.content = this.context.references.map(reference => reference.content).join('<br />');
          references.preview = this.context.references.map(reference => reference.preview).join('<br />');
        }
        return references;
      },
      backgrounds: function () {
        return [...this.toLinkArray(this.context?.backgrounds ?? [], "Reference Library"), {
          post: this.references,
          action: () => this.$emit('post-view', {post: this.references, section: "Reference Library"})
        }];
      },
      caseProcessing: function () {
        return this.toLinkArray(this.context?.caseProcessing ?? [], "Decision Aids");
      },
      guidelines: function () {
        return this.toLinkArray(this.context?.guidelines ?? [], "Coverage Guidelines");
      }
    },
    components: {
      ContextIndexSection,
      DocumentCptCodes,
    },
    methods: {
      handleCptCodeClick: function () {
        this.showCptCodes = true;
      },
      isActive: function (post) {
        return post === this.activePost;
      },
      toLinkArray: function(posts, section) {
        return posts.map(post => {
          return {
            post,
            action: () => this.$emit('post-view', {post, section})
          }
        })
      }
    },
    beforeMount() {
      if (this.$currentUser.loggedIn()) {
        this.cptCodes.getByTerm(this.$route.params.id);
      }
    }
  }
</script>

<style scoped>
  .index {
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(247,247,247, 0) 100%);
    height: 100%;

    font-size: 16px;
    line-height: 18px;
    color: #333333;
  }

  .index-title {
    padding: 8px 24px;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: #7F8080;
    background-color: #E0DFDE;
  }

  li {
    margin-top: 8px;
    margin-bottom: 0;
  }

  button.cpt-button {
    width: 100%;
    padding: 11px 0;
    background: #0B578B;
    border-radius: 2px;

    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
  }

  button.cpt-button:hover {
    opacity: 0.75;
  }

  .index :deep(.theme--light.v-expansion-panels .v-expansion-panel) {
    background: none;
  }

  .restricted {
    display: block;
    margin-top: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #0B578B;
  }

  .index :deep(.v-expansion-panel-header) {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
  }

  .index :deep(.v-expansion-panel-content__wrap) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .no-cpts {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #0B578B;
  }

</style>

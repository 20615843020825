<template>
  <v-container fluid class="overlay form-container">
    <v-row class="align-end">
      <v-icon :dark="true" @click="() => $emit('close')" class="mr-5 mt-5 ml-auto">mdi-close</v-icon>
    </v-row>
    <v-row class="align-center">
      <h2 class="mx-auto mt-0 mb-4">Get InformedGENOMICS™!</h2>
      <h4 class="mx-14 mb-8">Complete the form below and a product representative will reach out to help you get started.</h4>
    </v-row>
    <v-row>
      <v-container fluid class="pa-0">
        <validation-observer
            ref="observer"
            v-slot="{ validate, valid }"
        >
          <v-container fluid class="pa-0">
            <v-form @submit.prevent="validate().then(hubspotSubmit)" class="form mx-14">
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                      v-slot="{ errors }"
                      ref="firstname"
                      vid="firstname"
                      name="First Name"
                      rules="required"
                  >
                    <v-text-field
                        v-model="firstname"
                        :error-messages="errors"
                        label="First Name*"
                        filled dense hide-details=true background-color="white"
                        autocomplete="given-name"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                      v-slot="{ errors }"
                      ref="lastname"
                      vid="lastname"
                      name="Last Name"
                      rules="required"
                  >
                    <v-text-field
                        v-model="lastname"
                        :error-messages="errors"
                        label="Last Name*"
                        filled dense hide-details=true background-color="white"
                        autocomplete="family-name"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      ref="email"
                      vid="email"
                      name="Email"
                      rules="required|email"
                  >
                    <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        label="Business Email*"
                        filled dense hide-details=true background-color="white"
                        autocomplete="email"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      vid="phone"
                      name="phone"
                  >
                    <v-text-field
                        v-model="phone"
                        :error-messages="errors"
                        label="Phone Number"
                        filled dense hide-details=true background-color="white"
                        autocomplete="tel"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      ref="company"
                      vid="company"
                      name="Company"
                      rules="required"
                  >
                    <v-text-field
                        v-model="company"
                        :error-messages="errors"
                        label="Company Name*"
                        filled dense hide-details=true background-color="white"
                        autocomplete="organization"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      vid="jobtitle"
                      name="jobtitle"
                  >
                    <v-text-field
                        v-model="jobtitle"
                        :error-messages="errors"
                        label="Job Title"
                        filled dense hide-details=true background-color="white"
                        autocomplete="organization-title"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      vid="department"
                      name="department"
                  >
                    <v-text-field
                        v-model="department"
                        :error-messages="errors"
                        label="Department"
                        filled dense hide-details=true background-color="white"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <span>Business Segment</span>
                  <v-checkbox
                      dense hide-details=true
                      name="business_segment"
                      v-model="business_segment"
                      label="Medicare"
                      value="Medicare"
                      dark
                  ></v-checkbox>
                  <v-checkbox
                      dense hide-details=true
                      name="business_segment"
                      v-model="business_segment"
                      label="Medicaid"
                      value="Medicaid"
                      dark
                  ></v-checkbox>
                  <v-checkbox
                      dense hide-details=true
                      name="business_segment"
                      v-model="business_segment"
                      label="Commercial"
                      value="Commercial"
                      dark
                  ></v-checkbox>
                  <v-checkbox
                      dense hide-details=true
                      name="business_segment"
                      v-model="business_segment"
                      label="Other"
                      value="Other"
                      dark
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      vid="ig_inquiry_type"
                      name="ig_inquiry_type"
                  >
                    <v-select
                        name="ig_inquiry_type"
                        v-model="ig_inquiry_type"
                        :items="inquiryTypeOptions"
                        :error-messages="errors"
                        label="Inquiry Type"
                        filled dense hide-details=true background-color="white"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      vid="message"
                      name="message"
                  >
                    <v-textarea
                        v-model="message"
                        :error-messages="errors"
                        label="Write in a message" background-color="white"
                        filled dense
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-btn type="submit" block color="#45B98A" class="white--text py-6">SUBMIT</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container fluid class="error-bag pa-5" v-if="!valid && invalidSubmission">
            <v-icon size="32" color="#B8012D">mdi-alert-circle-outline</v-icon> Please complete all required fields
          </v-container>
        </validation-observer>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        invalidSubmission: false,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        company: null,
        jobtitle: null,
        department: null,
        business_segment: [],
        ig_inquiry_type: null,
        inquiryTypeOptions: ['View an InformedGENOMICS demo', 'Receive InformedGENOMICS pricing information', 'Sign up for InformedGENOMICS subscription', 'Other'],
        message: null,
        formFields: [
          "firstname",
          "lastname",
          "email",
          "phone",
          "company",
          "jobtitle",
          "department",
          "business_segment",
          "email",
          "phone",
          "ig_inquiry_type",
          "message"
        ]
      }
    },
    methods: {
      hubspotSubmit: function (valid) {
        if (!valid) {
          this.invalidSubmission = true;
          return false;
        }
        let data = {fields: []};
        this.formFields.forEach((field) => {
          data.fields.push({
            name: field,
            value: Array.isArray(this[field]) ? this[field].join(',') : this[field] ?? ''
          });
        });

        this.$ctaModal.register(data).then(() => {
          this.$alerts.success('Thank you. We will get back to you as quickly as we are able.');
        });
      }
    },
  }
</script>

<style scoped>
  .overlay {
    height: calc(100% - 100px);
    width: 660px;
    margin: 50px 0;
    overflow-y: auto;
    color: white;
  }

  .form-container {
    background-color: #004568;
    background-image: url('@/assets/shattered-ring-signup.svg');
    background-position: right -352px center;
    background-repeat: no-repeat;
  }

  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #FFFFFF;
  }

  h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }

  :deep(div.v-input--selection-controls__input) > label {
    color: white !important;
  }

  .error-bag {
    text-align: center;
    margin-top: 20px;
    background-color: #E0DFDE;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #B8012D;
  }

  .error-bag ul {
    list-style-type: none;
    padding: 0;
  }

</style>

<style>
  div.v-overlay__content {
    height: 100%;
  }
</style>
<template>
  <v-container fluid class="result">
    <v-row class="mb-2">
      <h3 class="result-title">{{ background.title }}</h3>
    </v-row>
    <v-row class="mb-6">
      <div v-html="background.preview"></div>
    </v-row>
    <v-row>
      <button class="mt-0 mb-0 button-detail" @click="onClick.call(this)">Read More</button>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    props: {
      background: {
        type: Object,
        required: true
      },
      onClick: {
        type: Function,
        required: true
      },
    }
  }
</script>

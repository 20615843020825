import {WordpressApiService, WordpressCustomApiService} from "@/services/wordpress/api";
import {MemberpressApiService} from "@/services/wordpress/memberpress-api";

const idnaBaseUrl = '/wp-json/api/v1';

export const $wp = {
    backgrounds: new WordpressCustomApiService(idnaBaseUrl, 'backgrounds'),
    caseProcessing: new WordpressCustomApiService(idnaBaseUrl, 'case-processing'),
    contexts: new WordpressCustomApiService(idnaBaseUrl, 'clinical-contexts'),
    guidelines: new WordpressCustomApiService(idnaBaseUrl, 'guidelines'),
    reference: new WordpressCustomApiService(idnaBaseUrl, 'reference'),
    cptCodes: new WordpressCustomApiService(idnaBaseUrl, 'cpt-codes'),
    cptCodesChangeSummary: new WordpressCustomApiService(idnaBaseUrl, 'cpt-codes-change-summary'),
    searchTerms: new WordpressCustomApiService(idnaBaseUrl, 'search-terms'),
    passwordReset: new WordpressCustomApiService(idnaBaseUrl, 'password-reset'),
    idnaUsers: new WordpressCustomApiService(idnaBaseUrl, 'users'),
    agreements: new WordpressCustomApiService(idnaBaseUrl, 'nccn-and-ama-agreements'),
    memberpress: new MemberpressApiService(),
    posts: new WordpressApiService('posts'),
    users: new WordpressApiService('users')
}
import {defineStore} from 'pinia'

export const cptCodesChangeSummaryStore = defineStore('cpt-codes-change-summary', {
    actions: {
        async search(params) {
            try {
                return await this.$wp.cptCodesChangeSummary.list(params);
            } catch (error) {
                console.log(error);
                return error;
            }
        },
    },
})

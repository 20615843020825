<template>
  <v-container fluid class="result">
    <v-row>
      <h3 class="result-title">{{ guideline.title }}</h3>
    </v-row>
    <v-row>
      <button class="mt-3 mb-0 button-detail" @click="onClick.call(this)">Read More</button>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    props: {
      guideline: {
        type: Object,
        required: true
      },
      onClick: {
        type: Function,
        required: true
      }
    },
  }
</script>


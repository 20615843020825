<template>
  <router-link
               :to="{name: 'login', params: {routePath: $route.path, routeParams: {...$route.params}, routeQuery: {...$route.query}}}">
    {{ text }}
  </router-link>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: false,
        default: 'LOG IN'
      }
    }
  }
</script>
<template>
  <v-overlay opacity="0.9" >
    <v-container fluid class="portal">
      <v-row class="mb-6 right-justify">
        <button class="close-button" @click="$emit('close')">
          <v-icon>mdi-window-close</v-icon>
        </button>
      </v-row>
      <v-row class="default-gutter scroll-container">
        <div class="scroll">
          <CptCodesDataTable :codes="cptCodes" :items-per-page="500"></CptCodesDataTable>
        </div>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>

  import CptCodesDataTable from "@/components/cpt-codes/CptCodesDataTable.vue";

  export default {
    props: {
      cptCodes: {
        type: Array,
        required: true
      }
    },
    components: {
      CptCodesDataTable
    }
  }
</script>

<style scoped>
  .portal {
    height: 100vh;
    width: 100vw;
  }

  .scroll-container {
    height: 100%;
  }

  .scroll {
    height: calc(100% - 200px);
    overflow: auto;
  }

  .close-button {
    padding: 16px;
    color: #000000;
    background-color: #FFFFFF;
    border-radius: 50%;
    margin: 45px 45px 0 0;
  }

  .close-button i {
    color: #000000;
    font-size: 24px;
    line-height: 24px;
  }

  .right-justify {
    flex-direction: row-reverse;
  }
</style>

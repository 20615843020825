<template>
  <v-container fluid class="post-content pa-0">
    <hr>
    <h3>CLICK HERE TO ACCESS THE LIST OF CHANGES FOR THIS DECISION AID</h3>
    <ChangeSummaryButton
      :disabled="buttonDisabled"
      :show-tooltip="buttonDisabled"
      :route="{name: 'decision-aids-change-summary', params: {id: caseProcessingId}}"
      class="mt-4"
    >
      <template v-slot:tooltip>
        <span>No Decision Aid Change Summary is available.</span>
      </template>
    </ChangeSummaryButton>
  </v-container>
</template>

<script>
  import ChangeSummaryButton from '@/components/buttons/ChangeSummaryButton.vue';
  import { caseProcessingStore } from '@/store/case-processing';

  export default {
    props: {
      caseProcessingId: {
        type: Number,
      }
    },
    data() {
      return {
        currentCaseProcessing: {
          count: 0
        }
      }
    },
    methods: {
      getChangeSummaryCount(postId) {

        if (postId === this.currentCaseProcessing.id) {
          return;
        }

        caseProcessingStore()
        .getChangeSummaryCount(postId)
        .then((response) => {
          this.currentCaseProcessing = {
            id : postId,
            count: response
          }
        });
      }
    },
    computed: {
      buttonDisabled() {
        return this.currentCaseProcessing.count === 0;
      }
    },
    watch: {
      caseProcessingId: function(newValue) {
        if (newValue) {
          this.getChangeSummaryCount(newValue);
        }
      }
    },
    mounted() {
      if (this.caseProcessingId){
        this.getChangeSummaryCount(this.caseProcessingId);
      }
    },
    components: {
      ChangeSummaryButton,
    },
  }
</script>
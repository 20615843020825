<template>
  <VContainer fluid>
    <VRow>
      <CtaGainAccess/>
    </VRow>
    <VRow class="atomic-lock"/>
  </VContainer>
</template>

<script>

  import CtaGainAccess from '@/components/call-to-action/CtaGainAccess';

  export default {
    components: {
      CtaGainAccess,
    },
  }
</script>

<style scoped>
  .atomic-lock {
    background-image: url('@/assets/background-atomic-lock.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: auto 26.8rem;
    margin: -24px 22px 80px 0;
    min-height: 26.8rem;
  }
</style>
<template>
  <page-with-footer>
    <template v-slot:content>
      <page-header class="default-gutter mb-6"/>
      <page-breadcrumbs/>
      <v-container fluid class="default-gutter" >
        <terms-of-use />
      </v-container>
    </template>
  </page-with-footer>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import PageWithFooter from "@/components/PageWithFooter";
  import PageBreadcrumbs from "@/components/PageBreadcrumbs";
  import TermsOfUse from "@/components/documents/TermsOfUse";

  export default {
    components: {
      TermsOfUse,
      PageBreadcrumbs,
      PageWithFooter,
      PageHeader
    },
    mounted() {
      this.$breadcrumbs.clear();
      this.$breadcrumbs.pushRoute({path: '/'}, 'Home', false);
      this.$breadcrumbs.pushRoute({path: '/terms-of-use'}, 'Terms of Use', true);
    }
  }
</script>

import {defineStore} from 'pinia'

export const backgroundsStore = defineStore('backgrounds', {
    state: () => ({
        items: [],
    }),
    actions: {
        async getByTerm(termId) {
            try {
                const paginator = await this.$wp.backgrounds.paginate({per_page: -1, endpoint: `${termId}/by-term`});
                this.items = paginator.items;
                return paginator;
            } catch (error) {
                console.log(error);
                return error;
            }
        },

        async getManyByIds(ids) {
            try {
                const items = await Promise.all(ids.map(async (id) => {
                    return await this.$wp.backgrounds.read(id);
                }));

                this.items = items;
                return items;
            } catch (error) {
                console.log(error);
                return error;
            }
        },

        search(params) {

            if (params?.t) {
                return this.$wp.backgrounds.read(params.t, 'by-term', params);
            }

            return this.$wp.backgrounds.read(null, '', params);
        }
    },
})

<template>
  <v-container fluid class="pa-0">
    <hr>
    <h1>Additional Resources</h1>
    <h2>InformedGENOMICS™&nbsp;User Guide:</h2>
    <p>This manual contains an overview of:</p>
    <ul>
      <li>How InformedGENOMICS™ can support your genetic test medical review</li>
      <li>How to search the database</li>
      <li>A best practice Step by Step guide to getting started with the case in front of you</li>
    </ul>
    <v-btn :disabled="!$currentUser.loggedIn()" :color="'#54b18c'" height="50" class="white--text download-button" target="_blank" :href="userGuideLink">
      DOWNLOAD NOW
    </v-btn>
    <h2>I have another question:</h2>
    <p>Contact us at <a href="mailto:informedgenomicssupport@informeddna.com">informedgenomicssupport@informeddna.com</a></p>
  </v-container>
</template>

<script>
  export default {
    computed: {
      apiUrl: function() {
        return this.$env?.api_url ?? '';
      },
      userGuideLink: function() {
        return `${this.apiUrl}/wp-json/api/v1/uploads/2023/02/InformedGENOMICS-User-Guide.pdf`;
      }
    },
  }
</script>

<style scoped>
.additional-resources-container {
  padding: 0 !important;
}

* {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}

h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #004568;
}

h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  color: #45b98a;
  margin: 16px 0 16px 0;
}


p {
  margin-bottom: 16px;
}

hr {
  margin: 40px 0 16px 0;
  border: 1px solid #888888;
}

.download-button {
  font-size: 20px;
  margin: 16px 0 30px 0;
}
</style>
<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attr }">
        <v-btn
            color="#E0DFDE"
            v-bind="attr"
            v-on="on"
            depressed
            class="menu-button"
        >
          <div class="d-inline-flex align-center" style="gap:8px">
            <v-img src="@/assets/icon-hamburger-menu.svg" height="16px" contain />
            <div>Resources</div>
          </div>
        </v-btn>
      </template>
      <v-list class="menu-list">
        <v-list-item link :to="{path:'/evidentiary-review'}">
          <v-list-item-title class="menu-item-title">
            Evidentiary Review
          </v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{path:'/cpt-code-scope-of-inclusion'}">
          <v-list-item-title class="menu-item-title">
            CPT® Code Scope of Inclusion
          </v-list-item-title>
        </v-list-item>
        <PermissionValidatorContainer :has-permission="$currentUser.loggedIn()">
          <v-list-item link :href="userGuideLink" target="_blank" >
            <v-list-item-title class="menu-item-title">
              Download User Guide
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link :to="{path: '/help-center'}">
            <v-list-item-title class="menu-item-title">
              Help Center
            </v-list-item-title>
          </v-list-item>
        </PermissionValidatorContainer>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import PermissionValidatorContainer from "@/components/permissions/PermissionValidatorContainer.vue";

  export default {
    computed: {
      apiUrl: function() {
        return this.$env?.api_url ?? '';
      },
      userGuideLink: function() {
        return `${this.apiUrl}/wp-json/api/v1/uploads/2023/02/InformedGENOMICS-User-Guide.pdf`;
      }
    },
    components: {
      PermissionValidatorContainer
    }
  }
</script>

<style scoped>
  .menu-button {
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #333333;

    padding: 10px !important;
    height: auto !important;
  }

  .menu-list {
    background-color: #E0DFDE;
  }

  .menu-item-title {
    font-family: 'Work Sans',serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #000000;

  }

</style>
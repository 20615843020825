import {defineStore} from 'pinia'

export const cptCodesStore = defineStore('cpt-codes', {
    state: () => ({items: []}),
    getters: {
        quarterDate: () => {

            let d = new Date();
            let day = 1;
            let quarter = Math.ceil(d.getMonth() / 3);
            let month = (1 + ((quarter - 1) * 3)); //arithmetic progression (1, 4, 7, 10)
            let year = d.getFullYear();

            return month + '/' + day + '/' + year;
        }
    },
    actions: {
        async getByTerm(termId) {
            try {
                const paginator = await this.$wp.cptCodes.paginate({per_page: -1, endpoint: `${termId}/by-term`});
                this.items = paginator.items;
                return paginator;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async getByName(cpt) {
            try {
                const paginator = await this.$wp.cptCodes.paginate({per_page: -1, endpoint: `${cpt}/by-name`});
                this.items = paginator.items;
                return paginator;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async search(params) {
            try {
                return await this.$wp.cptCodes.read(null, '', params);
            } catch (error) {
                console.log(error);
                return error;
            }
        },
    },
})

<template>
  <VContainer fluid class="mr-12">
    <VRow dense>
      <v-checkbox :value="checked" @change="handleChange" class="mt-0 pt-0">
        <template v-slot:label>
          <div class="newest-codes-label">Newest Codes Only</div>
        </template>
      </v-checkbox>
    </VRow>
  </VContainer>
</template>

<script>

  export default {
    data () {
      return {
        checked: false
      }
    },
    computed: {
      newestCodesOnly() {
        return this.$route.query.newest_codes === 'true';
      }
    },
    methods: {
      handleChange(checked) {
        if (checked) {
          this.$router.push({
            query: {
              newest_codes: 'true'
            }
          }).catch(() => {
          })
        } else {
          this.$router.push({query: {}}).catch(() => {
          });
        }
      }
    },
    mounted() {
      this.checked = this.newestCodesOnly;
    },
    watch: {
      newestCodesOnly(value) {
        this.checked = value;
      }
    }
  }
</script>

<style scoped>
  .newest-codes-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }
</style>

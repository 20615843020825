<template>
  <v-tooltip
    top
    content-class="ig-grey tooltip-top"
    color="#333333"
    :disabled="showTooltip == false"
  >

    <template v-slot:activator="{ on }">
      <div v-on="on" class="d-inline-block">
        <v-btn 
          :to="route"
          :disabled="disabled"
          class="m2 change-summary-button"
          color="#EDA341"
          target="_blank"
          outlined
          right
        >
          <v-icon left>mdi-history</v-icon><span>Change Summary</span>
        </v-btn>
      </div>
    </template>

    <slot name="tooltip"/>

  </v-tooltip>
</template>

<script>
  export default {
    props: {
      route: {
        default: {},
      },
      disabled: {
        default: false
      },
      showTooltip: {
        default: false
      }
    }
  }
</script>

<style scoped>
.change-summary-button {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: unset !important;
  height: 40px !important;
}
</style>
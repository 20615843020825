<template>
  <CptCodesDataTable :codes="cptCodes" :items-per-page="500" />
</template>

<script>
  import CptCodesDataTable from "@/components/cpt-codes/CptCodesDataTable.vue";

  export default {
    props: {
      cptCodes: {
        type: Array,
        required: true
      },
    },
    components: {
      CptCodesDataTable
    }
  }
</script>


import { render, staticRenderFns } from "./PostSearchResultList.vue?vue&type=template&id=4e872905&scoped=true&"
import script from "./PostSearchResultList.vue?vue&type=script&lang=js&"
export * from "./PostSearchResultList.vue?vue&type=script&lang=js&"
import style0 from "./PostSearchResultList.vue?vue&type=style&index=0&id=4e872905&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e872905",
  null
  
)

export default component.exports
import {defineStore} from 'pinia'

export const ctaModalStore = defineStore('cta-modal-state', {
    state: () => ({
        display: false,
        timer: null,
        clickReset: () => {},
        registered: false
    }),
    actions: {
        displayAfterTime: function(time = 20000) {
            this.cancelTimer();
            if (!this.registered) {
                this.timer = setTimeout(this.show, time);
            }
        },
        displayAfterInactivity: function (time = 20000) {
            this.clickReset = () => {
                this.cancelTimer();
                this.displayAfterTime(time);
                document.body.addEventListener('click', this.clickReset)
            }
            this.clickReset.call(this);
        },
        cancelTimer: function() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            document.body.removeEventListener('click', this.clickReset)
        },
        hide: function() {
            this.display = false;
        },
        show: function() {
            this.cancelTimer();
            this.display = true;
        },
        register: async function(data) {
            const response = await this.$hubspot.submitForm("ed5fb7ce-b0d9-47f6-8d65-84fca061a853", data);
            if (response) {
                this.registered = true;
                this.cancelTimer();
                this.hide();
                return response;
            } else {
                console.log('Error submitting hubspot form');
            }
        }
    },
    persist: true
})

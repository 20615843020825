<template>
  <v-row class="ma-0 align-content-center">

    <v-combobox
        v-model="terms.searched"
        :items="terms.items"
        :search-input.sync="search"
        @keyup="keypress"
        @click:clear="cleared"
        item-text="name"
        item-value="id"
        return-object no-filter flat solo clearable hide-no-data hide-details
        :placeholder="placeholder"
        :prepend-icon="prependIcon"
        append-icon=""
        class="autocomplete flex-grow-1 ma-0"
        ref="combo"
        :disabled="disabled"
    >

      <template v-slot:prepend>
        <slot name="prepend">
          <div class="icon-magnifying-glass ml-4" :class="{disabled:disabled}"></div>
        </slot>
      </template>

      <template v-slot:label>
        <slot name="label"></slot>
      </template>

      <template v-slot:item="data">
        <SearchBarFormattedItems
            :data="data"
            :searched-phrase="search"
            :limit-words-before-match="limitWordsBeforeMatch"
            :search-highlight-type="searchHighlightType"
        />
      </template>

    </v-combobox>

    <v-col class="button-container flex-grow-0">
      <slot name="button">
        <button class="search-button" @click="buttonHandler" :disabled="disabled">{{ buttonText }}</button>
      </slot>
    </v-col>

  </v-row>
</template>

<script>
  import SearchBarFormattedItems from '@/components/search/SearchBarFormattedItems';

  import {debounce} from 'vue-debounce';

  export default {
    data() {
      return {
        search: null,
        doSearch: null,
      }
    },
    props: {
      buttonText: {
        type: String,
        default: 'GO'
      },
      placeholder: {
        type: String,
        default: 'SEARCH'
      },
      prependIcon: {
        type: String,
        default: ''
      },
      storeClass: {
        required: true
      },
      initialTerm: {
        default: null
      },
      limitWordsBeforeMatch: {
        default: null
      },
      searchHighlightType: {
        default: 'words'
      },
      disabled: {
        default: false
      }
    },
    computed: {
      terms: function () {
        return typeof this.storeClass !== 'undefined' ? new this.storeClass() : {}
      }
    },
    watch: {
      search(val) {
        if (val) {
          this.doSearch(val);
        } else {
          this.terms.items = [];
        }
      },
      initialTerm: {
        handler: function (term) {
          this.terms.searched = term;
        },
        deep: true
      }
    },
    methods: {
      cleared: function () {
        // noop
      },
      selected: function () {
        this.$emit('selected', this.terms.searched);
      },
      buttonHandler: function () {
        this.searchFromText();
      },
      keypress: function (e) {
        if (e.key === 'Enter') {
          this.searchFromText();
        }
      },
      searchFromText: function () {
        const search = this.getSearch();
        this.$refs.combo.blur();
        if (search) {
          this.getTermFromText(search)
              .then(term => {
                this.terms.searched = term;
                this.selected();
              });
        }
      },
      getTermFromText: async function (search) {
        if (search) {
          if (Array.isArray(this.terms.items)) {
            const found = this.terms.items.find(item => item.name.toUpperCase() === search.toUpperCase());
            if (found?.id) {
              this.terms.searched = {...found};
              return found;
            }
          }
          const termByName = await this.terms.get(search);
          if (termByName?.id) {
            return {
              id: parseInt(termByName.id),
              name: termByName.name
            };
          }
        }
        return {
          name: search,
          id: null
        };
      },
      getSearch: function () {
        return typeof this.search === 'string' ? this.search.trim() : '';
      }
    },
    beforeMount() {
      this.doSearch = debounce(val => {
        if (!val.trim() || (val.length < 2)) {
          this.terms.items = [];
          return false;
        }

        this.terms.list(val);
      }, 250);

      if (this.initialTerm) {
        this.terms.searched = this.initialTerm;
      }

      // add watcher after initialization
      this.$watch('terms.searched', function () {
        if (this.terms.searched?.id && (this.terms.searched?.id !== this.initialTerm?.id)) {
          this.selected();
        }
      });
    },
    components: {
      SearchBarFormattedItems,
    },
  }
</script>

<style scoped>
  button.search-button {
    width: 266px;
    height: 48px;
    background-color: #EDA341;
    border-color: #EDA341;
    color: #FFFFFF;

    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }

  button.search-button:disabled {
    background: #EEEEEE;
    color: #888888;
  }

  .autocomplete :deep(input) {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    color: #888888;
  }

  .autocomplete :deep(input:disabled) {
    color: #CCCCCC;
  }

  .autocomplete {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;

    color: #000000;

    border: 2px solid #CCCCCC;
    border-radius: 1px;
    height: 48px;
  }

  :deep(.autocomplete.v-input--is-disabled) {
    border: 2px solid #E0DFDE;
  }

  .button-container {
    padding: 0;
  }

  :deep(.v-input__control) {
    min-height: inherit !important;
    margin: auto !important;
  }

  :deep(label.v-label) {
    min-height: 36px;
  }

  .icon-magnifying-glass {
    mask-image: url("@/assets/icon-search.svg");
    background-color: #888888;
    mask-size: contain;
    mask-repeat: no-repeat;
    width: 23px;
    height: 23px;
  }

  .icon-magnifying-glass.disabled {
    background-color: #E0DFDE;
  }
</style>

<template>
  <v-container>
    <v-row align-content="center">
      <v-col>
        <v-container class="form-container">
          <v-form ref="form" @submit.prevent="resetPassword" v-model="formValid">
            <v-row dense>
              <v-col>
                <h2>Please create a new password</h2>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <span>Your new Password must include:</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <ul>
                  <li v-for="(rule, index) in passwordRules" :key="index">{{ rule }}</li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    class="rounded-sm login"
                    color="#333333"
                    v-model="password"
                    :rules="[rules.length, rules.uppercase, rules.lowercase, rules.number, rules.special]"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Password"
                    @click:append="show1 = !show1"
                    outlined validate-on-blur
                >
                  <template v-slot:append>
                    <v-img v-if="show1" src="@/assets/icon-eye.svg" @click="show1 = !show1" class="icon-hover"/>
                    <v-img v-else src="@/assets/icon-eye-closed.svg" @click="show1 = !show1" class="icon-hover"/>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    class="rounded-sm login"
                    color="#333333"
                    v-model="passwordConfirm"
                    :rules="[rules.length, rules.matches]"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Confirm Password"
                    @click:append="show2 = !show2"
                    outlined validate-on-blur
                >
                  <template v-slot:append>
                    <v-img v-if="show2" src="@/assets/icon-eye.svg" @click="show2 = !show2" class="icon-hover"/>
                    <v-img v-else src="@/assets/icon-eye-closed.svg" @click="show2 = !show2" class="icon-hover"/>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pa-0">
                <v-btn
                    class="send rounded-sm no-uppercase"
                    :class="{active: formValid}"
                    text x-large
                    :disabled="!formValid"
                    type="submit"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {passwordResetStore} from "@/store/password-reset";

  export default {
    data() {
      return {
        formValid: false,
        password: null,
        passwordConfirm: null,
        show1: false,
        show2: false,
        rules: {
          length: value => value?.length >= 8 || 'Must be at least 8 characters',
          uppercase: value => !!value?.match(/[A-Z]/) || 'Must contain an uppercase letter',
          lowercase: value => !!value?.match(/[a-z]/) || 'Must contain a lower case letter',
          number: value => !!value?.match(/[0-9]/) || 'Must contain a number',
          special: value => !!value?.match(/[()!@#$%^&*\-_+?]/) || 'Must contain one of ()!@#$%^&*-_+?',
          matches: value => value === this.password || `Passwords don't match`,
        },
        passwordRules: [
          'At least 8 characters',
          'An uppercase letter',
          'A lowercase letter',
          'A number',
          'A special character ()!@#$%ˆ&*_-+?'
        ]
      }
    },
    computed: {
      passwordReset: () => passwordResetStore()
    },
    methods: {
      resetPassword: async function() {
        if (!this.$refs.form.validate) {
          return false;
        }

        const response = await this.passwordReset.setPassword(this.password);

        if (response !== true) {
          // handle error
        }

        await this.$router.push({name: 'home'});
      }
    }
  }
</script>

<style scoped>
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #004568;
  }

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

  li {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

  .form-container {
    background-color: #FFFFFF;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    margin: 30px auto;
    max-width: 770px;
    padding: 40px 56px;
  }

  .send {
    width: 100%;
    padding: 27px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    background-color: #EEEEEE;
  }

  .send.active {
    background-color: #EDA341;
    color: #FFFFFF;
  }

  .send.active:hover {
    opacity: 0.7;
  }

  .send {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #888888;
  }

  .login {
    color: #333333;
  }

  .login :deep(input) {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
  }

  .login :deep(label) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .no-uppercase {
    text-transform: unset !important;
  }

  .icon-hover {
    cursor: pointer;
  }
</style>
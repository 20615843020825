<template>
  <PostSearchHeader>
    <template v-slot:header-title>
      Reference Library
    </template>
    <template v-slot:header-description>
      Comprehensive summaries for ~300 conditions and decision aids for applicable genetic testing strategies.
    </template>
  </PostSearchHeader>
</template>

<script>
  import PostSearchHeader from '@/components/search/posts/PostSearchHeader';

  export default {
    components: {
      PostSearchHeader,
    },
  }
</script>
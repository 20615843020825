<template>
  <v-fade-transition>
    <v-btn v-show="showButton" color="#0B578B" @click="scrollToTop" class='scroll-to-top' fixed fab>
      <v-icon color="white" size="45">mdi-chevron-up</v-icon>
    </v-btn>
  </v-fade-transition>
</template>

<script>

  import { debounce } from 'vue-debounce';

  export default {
    data() {
      return {
        scrollPosition: 0,
      }
    },
    created () {
      window.addEventListener('scroll', debounce(this.watchScroll, 50));
    },
    destroyed () {
      window.removeEventListener('scroll', debounce(this.watchScroll, 50));
    },
    methods: {
      watchScroll() {
        this.scrollPosition = window.scrollY;
      },
      scrollToTop() {
        this.scrollPosition = 0;
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    computed: {
      showButton() {
        return this.scrollPosition > 450;
      }
    },
  }
</script>

<style scoped>
.scroll-to-top {
  top:300px;
  right:50px;
}
</style>
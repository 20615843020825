<template>
  <search-bar
      :button-text="searchButtonText"
      :placeholder="placeholder"
      :store-class="storeClass"
      :initial-term="initialTerm"
      @selected="selected"
  >
    <template v-slot:label >
      <span class="search-label">{{ label }}</span>
    </template>
  </search-bar>
</template>

<script>
  import {searchTermsStore} from "@/store/search-terms";
  import SearchBar from "../SearchBar";

  export default {
    data() {
      return {
        searchButtonText: "GO",
        placeholder: '',
        label: 'Search for Disease names, ICD-10 Codes, CPT® Codes, Genes',
        storeClass: searchTermsStore
      }
    },
    props: {
      initialTerm: {
        default: null
      }
    },
    computed: {
      terms: () => searchTermsStore()
    },
    methods: {
      selected: function (item) {
        if (item === null) {
          this.terms.reset();
        }
        this.$emit('selected', item);
      },
    },
    components: {
      SearchBar
    }
  }
</script>

<style scoped>

  :deep(.search-label) {
    font-weight: 400;
    font-size: 20px;
    color: #888888;
  }

  </style>
<template>
  <v-overlay :value="show" :light="true" :dark="false">
    <v-container class="fill-height d-flex align-center">
      <end-user-license-agreement />
    </v-container>
  </v-overlay>
</template>

<script>
  import EndUserLicenseAgreement from "@/components/user-agreements/EndUserLicenseAgreement";

  export default {
    components: {
      EndUserLicenseAgreement,
    },
    computed: {
      show: function() {
        return this.$currentUser.loggedIn()
            && this.$currentUser.has_accepted_end_user_license_agreement === false
            && !this.$route.meta?.ignoreEndUserLicenseAgreement;
      }
    }
  }
</script>



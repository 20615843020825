import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import {wpApiPlugin} from "@/store/plugins/wp-api";
import {hubspotApiPlugin} from "@/store/plugins/hubspot-api";
import {cookiesPlugin} from "@/store/plugins/cookies";
import {envPlugin} from "@/store/plugins/env";
import {fullstoryPlugin} from "@/store/plugins/fullstory";
import { PiniaSharedState } from 'pinia-shared-state';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(wpApiPlugin);
pinia.use(hubspotApiPlugin);
pinia.use(cookiesPlugin);
pinia.use(envPlugin);
pinia.use(fullstoryPlugin);

pinia.use(
  PiniaSharedState({
    enable: false,
    initialize: false,
    type: 'localstorage',
  })
);

export default pinia;
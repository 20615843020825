<template>
    <v-container fluid class="finger-print-bg">
      <v-container fluid class="my-14 default-gutter">
        <v-row>
          <v-col cols="4">
            <contact-us-info />
          </v-col>
          <v-col cols="8" class="test">
            <contact-us-form @complete="complete"/>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
</template>

<style scoped>
  .finger-print-bg {
    background-color: #004568;
    position: relative;
    z-index: 0
  }

  .finger-print-bg:after {
    display:block;
    content: " ";
    background-color: rgba(0, 0, 0, 0.85);
    mask: url('@/assets/background-finger-prints.svg') no-repeat center;
    mask-size: 100% auto;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

</style>
<script>
  import ContactUsInfo from "@/components/contact-us/ContactUsInfo";
  import ContactUsForm from "@/components/contact-us/ContactUsForm";
  export default {
    components: {ContactUsInfo, ContactUsForm},
    methods: {
      complete: function() {
        this.$router.push({name: 'home'}).then(() => {
          this.$alerts.success('Thanks for contacting us. We will get back to you as quickly as we are able.')
        });

      }
    }
  }
</script>
<template>
  <auth-page>
    <forgot-password-form
        v-if="!sent"
        :email-initial="email"
        @sent="sent = true"
    />
    <forgot-password-email-sent-form v-else />
  </auth-page>
</template>

<script>
  import AuthPage from "@/components/auth/AuthPage";
  import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm";
  import ForgotPasswordEmailSentForm from "@/components/auth/ForgotPasswordEmailSentForm";

  export default {
    data() {
      return {
        sent: false
      }
    },
    components: {AuthPage, ForgotPasswordForm, ForgotPasswordEmailSentForm},
    props: {
      email: {
        type: String,
        default: null,
      },
    }
  }
</script>

<template>
  <page-with-footer>
    <template v-slot:content>
      <page-header class="default-gutter mb-6"/>
      <page-breadcrumbs/>
      <v-container fluid class="default-gutter" >
        <privacy-policy />
      </v-container>
    </template>
  </page-with-footer>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import PageWithFooter from "@/components/PageWithFooter";
  import PageBreadcrumbs from "@/components/PageBreadcrumbs";
  import PrivacyPolicy from "@/components/documents/PrivacyPolicy";

  export default {
    components: {
      PrivacyPolicy,
      PageBreadcrumbs,
      PageWithFooter,
      PageHeader,
    },
    mounted() {
      this.$breadcrumbs.clear();
      this.$breadcrumbs.pushRoute({path: '/'}, 'Home', false);
      this.$breadcrumbs.pushRoute({path: '/privacy-policy'}, 'Privacy Policy', true);
    }
  }
</script>

<template>
  <v-container fluid class="pa-0 d-flex page-with-footer flex-column justify-space-between">
    <v-row>
      <v-col class="pa-0 pb-2">
        <slot name="content"/>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0 ma-0">
      <v-col class="pa-0">
        <slot name="footer">
          <page-footer/>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import PageFooter from "@/components/PageFooter";

  export default {
    components: {PageFooter}
  }
</script>

<style scoped>
  .page-with-footer {
    min-height: 100vh;
  }
</style>

<style>
  html {
    overflow-y: auto !important;
  }
</style>
<template>
  <v-container fluid class="pa-0 mb-14">
    <section-title :title="title" v-if="title"/>
    <slot></slot>
  </v-container>
</template>

<script>
  import SectionTitle from '@/components/search/results/SectionTitle';

  export default {
    props: {
      title: {
        type: String,
        required: false
      }
    },
    components: {
      SectionTitle
    }
  }
</script>

<style scoped>

  :deep(.result), :deep(.result h4) {
    margin: 24px 0 40px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  :deep(.result h4) {
    margin: 8px 0;
  }

  :deep(h3.result-title) {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  :deep(.button-detail) {
    border: 2px solid #0B578B;
    border-radius: 2px;
    padding: 12px 36px;
    margin: 32px 0;

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0B578B;
    background-color: #FFFFFF;
  }

  :deep(.button-detail:hover) {
    background-color: #0B578B;
    color: #FFFFFF;
  }
</style>

import {defineStore} from 'pinia'
import {clinicalContextsStore} from "@/store/clinical-contexts";
import {alertsStore} from "@/store/alerts";
import {cookieStore} from "@/store/cookies";

export const currentUserStore = defineStore('current-user', {
    state: () => ({
        id: '',
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        registered_date: '',
        roles: [],
        token: '',
        has_accepted_end_user_license_agreement: '',
        has_accepted_third_party_license_agreement: false,
    }),
    share: {
        enable: true,
    },
    getters: {
        emailDomain: (state) => {
            if (!state.email) {
                return "";
            }
            const regex = /@[^.]+(?=\.)/; // safari doesn't support lookbehind
            const found = state.email.match(regex);

            if (!Array.isArray(found)) {
                return "";
            }

            return found[0].replace('@', '') ?? "";
        },
        fullStoryUserVars: (state) => {
            return {
                ...(state.email && { email: state.email}),
                ...(state.fullName && { displayName: state.fullName})
            }
        },
        fullName: (state) => `${state.first_name} ${state.last_name}`.trim().split(' ').join(' ')
    },
    actions: {
        async login(username, password) {
            try {
                // Authenticate
                const user = await this.$wp.users.token(username, password);
                if (!user.token) {
                    return false;
                }
                this.token = user.token;
                this.username = user.user_display_name;
                this.email = user.user_email;

                // Get and set user metadata
                const me = await this.me();
                this.first_name = me.first_name;
                this.last_name = me.last_name;
                this.roles = me.roles;
                this.id = me.id;
                this.registered_date = me.registered_date;

                // FullStory Identify
                this.identify();

                // Check if accepted EULA
                this.has_accepted_end_user_license_agreement = !!(await this.hasAcceptedEndUserLicenseAgreement().catch(() => this.has_accepted_end_user_license_agreement = false));

                let cookies = cookieStore();
                cookies.set('X-auth-token', this.token, '90d', '/');
                this.clearStoreCache();

                return true;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        loggedIn() {
            return !!this.token;
        },
        clearStoreCache() {
            const contexts = clinicalContextsStore();
            contexts.$reset();
        },
        logout() {
            this.$reset();
            let cookies = cookieStore();
            cookies.remove('X-auth-token');

            this.clearStoreCache();
        },
        async me() {
            if (!this.token) {
                throw new Error('Not authorized');
            }
            return await this.$wp.users.read(null, 'me', {context: 'edit'});
        },
        async get(id, context = 'edit') {
            return await this.$wp.users.read(id, null, {context: context});
        },
        async acceptEndUserLicenseAgreement() {
            if (!this.loggedIn()) {
                return false;
            }
            const response = await this.$wp.idnaUsers.update(this.id, {}, 'license-agreement/accept');
            if (['Success', 'Record exists'].includes(response)) {
                this.has_accepted_end_user_license_agreement = true;
                return true;
            } else {
                const alerts = alertsStore();
                alerts.error("Error communicating with server");
                return false;
            }
        },
        async hasAcceptedEndUserLicenseAgreement() {
            if(!this.loggedIn()) {
                return false;
            }
            const response = await this.$wp.idnaUsers.read(this.id, 'license-agreement/has-accepted');
            return !!response['End User License Agreement'];
        },
        async acceptThirdPartyLicenseAgreement() {
            this.has_accepted_third_party_license_agreement = true;
            const response = await this.$wp.agreements.create({}, 'accept');
            if (response !== 'Success') {
                const alerts = alertsStore();
                alerts.error("Error communicating with server");
            }
        },
        identify() {
            if (this.id) {
                this.$FullStory.identify(this.id, this.fullStoryUserVars);
            }
        }
    },
    persist: true
})

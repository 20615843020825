<template>
  <v-container fluid class="pa-0 mb-6">
    <h2>{{ title }}</h2>
  </v-container>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      }
    },
  }
</script>

<style scoped>
  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    text-decoration-line: underline;
    color: #004568;
  }
</style>

<template>
  <page-with-footer>
    <template v-slot:content>
      <page-header class="default-gutter"/>
      <global-search-bar class="default-gutter mt-6 mb-10" @selected="selected"/>
      <landing-welcome />
      <landing-cards class="default-gutter" />
    </template>
  </page-with-footer>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import PageWithFooter from "@/components/PageWithFooter";
  import GlobalSearchBar from "@/components/search/global/GlobalSearchBar";
  import LandingWelcome from "@/components/LandingWelcome";
  import LandingCards from "@/components/LandingCards";

  export default {
    components: {
      PageWithFooter,
      PageHeader,
      GlobalSearchBar,
      LandingWelcome,
      LandingCards
    },
    methods: {
      selected: function(item) {
        this.$router.push({
          path: '/search',
          query: {
            q: item?.name,
            t: item?.id
          }
        }).catch(() => {
        });
      }
    }
  }
</script>
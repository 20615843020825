<template>
  <table>

      <thead>
        <tr>
          <th>Search Term to Consider</th>
          <th>Case Example 1</th>
          <th>Case Example 2</th>
          <th>Case Example 3</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in content" :key="index">
          <td> {{ item.search_term_name }} </td>
          <td> {{ item.first_case || '---' }} </td>
          <td> {{ item.second_case || '---' }} </td>
          <td> {{ item.third_case || '---' }} </td>
        </tr>
      </tbody>

  </table>
</template>

<script>
  export default {
    data() {
      return {
        content: [
          {
            search_term_name: 'Disease Name',
            first_case: '',
            second_case: 'Breast cancer',
            third_case: '',
          },
          {
            search_term_name: 'ICD-10 diagnosis code	',
            first_case: 'N96',
            second_case: '',
            third_case: 'F33.2',
          },
          {
            search_term_name: 'Proprietary test name',
            first_case: '',
            second_case: 'OncotypeDx Breast Recurrence Score',
            third_case: '',
          },
          {
            search_term_name: 'Related Keyword',
            first_case: 'miscarriage',
            second_case: 'tumor testing',
            third_case: 'drug metabolism',
          },
          {
            search_term_name: 'CPT code',
            first_case: '81291',
            second_case: '81519',
            third_case: '81226',
          },
          {
            search_term_name: 'Gene name',
            first_case: 'MTHFR',
            second_case: '',
            third_case: 'CYP2D6',
          },
          {
            search_term_name: 'Indication for Testing',
            first_case: 'Recurrent Pregnancy Loss',
            second_case: 'Gene expression profiling',
            third_case: 'Pharmacogenetic testing',
          },
        ]
      }
    },
  }
</script>

<style scoped>

table {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #99acc2;
}

th, td {
  padding: 4px 10px;
}

th {
  font-weight: 500;
  color: white;
  background-color: #004568;
}

td {
  border: 1px solid #eeeeee;
  color: #004568;
}

tbody > tr > td:first-child {
  font-weight: bold;
  color: #55b991;
}
</style>
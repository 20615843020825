<template>
  <v-container fluid class="result hr">
    <v-row class="mb-2">
      <h3 class="result-title">{{ background.title }}</h3>
    </v-row>
    <v-row>
      <button class="mt-0 mb-4 button-detail" @click="onClick.call(this)">
        Read More
      </button>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    background: {
      type: Object,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style>
.result-title {
  font-size: 20px !important;
}

.hr {
  border-bottom: 1px solid #cccccc;
}
</style>

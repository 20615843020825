<template>
  <v-list-item-content class="content-padding">
    <v-list-item-title v-html="formatItem(data.item.name)" class="autocomplete-result"/>
  </v-list-item-content>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      },
      searchedPhrase: {
        type: String,
        default: ''
      },
      searchHighlightType: {
        default: 'words'
      },
      limitWordsBeforeMatch: {
        default: null
      }
    },
    methods: {
      formatItem(phrase) {

        if (!this.searchedPhrase) {
          return '';
        }

        if (this.limitWordsBeforeMatch !== null) {
          phrase = this.removeWordsBeforeMatch(phrase);
        }

        return this.highlightItem(phrase);
      },
      removeWordsBeforeMatch(phrase) {

        const formattedPhrase = phrase.trim().toLowerCase();

        const matchPosition = formattedPhrase.search('([^\\s]*)' + this.escapedSearchedPhrase);

        const phraseBeforeMatch = phrase.substring(0, matchPosition);
        const wordsBeforeMatch = phraseBeforeMatch.trim().split(' ');

        let prefix = '';

        if (wordsBeforeMatch.length > this.limitWordsBeforeMatch) {
          prefix = '... ';
        }

        return prefix + wordsBeforeMatch.splice(this.limitWordsBeforeMatch * -1).join(' ') + ' ' + phrase.substring(matchPosition).trim();
      },
      highlightItem(phrase) {

        if (this.searchHighlightType == 'words') {
          return this.highlightSearchedWords(phrase);
        }

        return this.highlighSearchPhrase(phrase);
      },
      highlightSearchedWords(phrase) {
        return phrase.split(' ').map(word => {

          const formattedWord = word.replace(/^[(]+/, '').replace(/[)]+$/, '').toLowerCase();

          const match = this.searchedPhrase.trim().split(' ').find((searchedWord) => {
            const formattedSearchedWord = searchedWord.replace(/^[(]+/, '').replace(/[)]+$/, '').toLowerCase();

            if ((formattedWord == formattedSearchedWord) || formattedWord.startsWith(formattedSearchedWord)) {
              return formattedSearchedWord;
            }
          });

          if (typeof match == 'undefined') {
            return word;
          }

          return `<span class="autocomplete-result-highlight">${word.substring(0, match.length)}</span>${word.substring(match.length)}`;
        }).join(' ');
      },
      highlighSearchPhrase(phrase) {
        const searchRegExp = new RegExp(`(${this.escapedSearchedPhrase})`, 'ig');
        const searchReplaceString = '<span class="autocomplete-result-highlight">$1</span>';

        return phrase.trim().replace(searchRegExp, searchReplaceString);
      }
    },
    computed: {
      escapedSearchedPhrase() {
        return this.searchedPhrase.trim().replace(/[-[\]{}()*+?.,\\^$|]/g, "\\$&").toLowerCase();
      }
    },
  }
</script>

<style>
  .autocomplete-result {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;

    color: #000000 !important;
  }

  .autocomplete-result-highlight {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0B578B;
  }
</style>
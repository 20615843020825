import {defineStore} from 'pinia'

export const passwordResetStore = defineStore('password-reset', {
    state: () => ({
        email: null,
        token: null
    }),
    actions: {
        async sendResetEmail(email = null) {
            email = email ?? this.email;
            if (!email) {
                throw new Error('Email is required');
            }
            return await this.$wp.passwordReset.create({email: email}, 'send-email', );
        },
        async validateToken(token = null, email = null) {
            token = token ?? this.token;
            email = email ?? this.email;
            if (!token || !email) {
                throw new Error('Email and token are required');
            }
            return await this.$wp.passwordReset.read(null, 'validate', {
                email: email,
                token: token
            });
        },
        async setPassword(password, token = null, email = null) {
            token = token ?? this.token;
            email = email ?? this.email;
            if (!token || !email || !password) {
                throw new Error('Password, email and token are required');
            }
            return await this.$wp.passwordReset.create({
                email: email,
                token: token,
                password: password
            });
        }
    },
    persist: true
})

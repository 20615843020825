<template>
  <v-container fluid class="ma-0">
    <v-row class="justify-start gap-20 ma-0 mb-10">

      <div class="landing-card card-green">
        <div>
          <h2>Coverage Guidelines</h2>
          <p>
            Evidence-based coverage guidelines developed by genetics specialists with clinical expertise and research
            experience.</p>
        </div>
        <div>
          <button class="card-button" @click="$router.push({name:'search-guidelines'})">View</button>
        </div>
        <img src="@/assets/molecule-icon.svg" class="card-icon" alt=""/>
      </div>

      <div class="landing-card card-blue">
        <div>
          <h2>Reference Library</h2>
          <p>
            Comprehensive summaries for ~300 conditions and decision aids for applicable genetic testing strategies.</p>
        </div>
        <div>
          <button class="card-button" @click="$router.push({name:'search-references'})">View</button>
        </div>
        <img src="@/assets/cube-icon.svg" class="card-icon icon-cube" alt=""/>
      </div>

      <div class="landing-card card-orange">
        <div>
          <h2>CPT® Code Insights</h2>
          <p>CPT® Code insights to help decipher molecular code descriptions and applications.</p>
        </div>
        <div>
          <button class="card-button" @click="$router.push({name: 'search-cpt-codes'})" >View</button>
        </div>
        <img src="@/assets/triangle-icon.svg" class="card-icon" alt=""/>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LandingCards',
  }
</script>

<style scoped>

  p.disclaimer {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  h2 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;

    margin-bottom: 8px;
  }

  p {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 24px;
  }

  .landing-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 40px;
    position: relative;
    min-width: calc(187px + 80px);
  }

  .landing-card.card-green {
    background-color: #45B98A;
  }

  .landing-card.card-blue {
    background-color: #0B578B;
  }

  .landing-card.card-orange {
    background-color: #EDA341;
  }

  .outlined {
    border: 3px solid;
    border-radius: 2px;
  }

  .landing-card.card-orange.outlined {
    border-color: #EDA341;
    color: #EDA341;
  }

  .landing-card.card-orange.outlined::after {
    content: "Coming soon!*";
    display: inline-block;
    position: absolute;
    padding: 17px 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    top: -27px;
    right: -8px;
    background-color: #EDA341;
  }

  .landing-card.card-blue.outlined {
    border-color: #0B578B;
    color: #0B578B;
  }

  .landing-card.card-blue.outlined::after {
    content: "Coming soon!*";
    display: inline-block;
    position: absolute;
    padding: 17px 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    top: -27px;
    right: -8px;
    background-color: #0B578B;
  }

  .card-orange.outlined p, .card-orange.outlined h2 {
    color: #EDA341;
  }

  .card-blue.outlined p, .card-blue.outlined h2 {
    color: #0B578B;
  }

  .landing-card p {
    margin-bottom: 24px;
  }

  .card-button {
    border: 2px solid #FFFFFF;
    border-radius: 2px;

    padding: 14px 68px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;

    background-color: rgba(255, 255, 255, 1);
    justify-self: end;
  }

  div.card-green button.card-button {
    color: #45B98A;
  }

  .card-blue .card-button {
    color: #0B578B;
  }

  .card-orange .card-button {
    color: #EDA341;
  }

  .card-button:hover:not(.disabled) {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
  }

  .card-button.disabled {
    background: #E0DFDE;
    color: #7F8080;
  }

  .card-icon {
    position: absolute;
    right: 40px;
    bottom: 24px;
    height: 114px;

    color: #FFFFFF
  }

  .icon-cube {
    height: 84px;
  }

  .gap-20 {
    gap: 20px;
    row-gap: 40px;
  }

</style>

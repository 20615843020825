<template>
    <PageBreadcrumbs />
</template>

<script>
  import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';

  export default {
    beforeMount: function () {
      this.setBreadcrumbs();
    },
    methods: {
      setBreadcrumbs: function () {
        this.$breadcrumbs.clear();
        this.$breadcrumbs.pushRoute({path: '/'}, 'Home');
        this.$breadcrumbs.pushRoute({path: '/help-center'}, 'Help Center', true);
      },
    },
    components: {
      PageBreadcrumbs,
    },
  }
</script>

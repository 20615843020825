<template>
  <v-container fluid class="global-no-results">
    <v-row align="center" no-gutters class="mb-6">
      <div class="no-results">No results found for "{{ term.name }}"</div>
    </v-row>

    <div>
      Consider searching by disease name, clinical keywords, ICD-10 code, type of test, CPT
      code, and/or genes.
    </div>
    <div>
      The
      <a link :href="userGuideLink" target="_blank">User Guide</a> and
      <a link :href="helpCenterLink">Help Center</a> also provide additional
      searching tips and tricks.
    </div>

    <v-row align="center" no-gutters class="mt-16">
      <div class="section-title">Suggested starting points:</div>
    </v-row>

    <section-container>
      <result-background-no-preview
        v-for="(background, index) in this.store.items"
        :key="'background_' + index"
        :background="background"
        :onClick="() => clickBackground(background)"
      />
    </section-container>

    <v-row align="center" no-gutters class="mb-6">
      <div class="section-title">Browse by topics:</div>
    </v-row>
  </v-container>
</template>

<script>
import SectionContainer from "@/components/search/results/SectionContainer";
import ResultBackgroundNoPreview from "@/components/search/results/ResultBackgroundNoPreview";

import { backgroundsStore } from "@/store/backgrounds";

export default {
  components: {
    SectionContainer,
    ResultBackgroundNoPreview,
  },
  props: {
    term: {
      required: true,
    },
  },
  computed: {
    apiUrl: function () {
      return this.$env?.api_url ?? "";
    },
    userGuideLink: function () {
      return `/downloads/2023/02/InformedGENOMICS-User-Guide.pdf`;
    },
    helpCenterLink: function () {
      return "/help-center";
    },
    store: () => backgroundsStore(),
  },
  methods: {
    clickBackground(background) {
      this.$router.push({
        name: "clinical-context",
        params: { id: background.context_id },
        query: {
          q: this.term.name,
          t: this.term.id,
        },
      });
    },
  },
  beforeMount() {
    // fetch suggested pages
    this.store.getManyByIds([1251, 1727, 1789]);
  },
};
</script>

<style scoped>
.global-no-results {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

.global-no-results a {
  color: #eda341;
  font-weight: 600;
  text-decoration: underline;
}

.section-title {
  color: #0b578b;
  font-weight: 600;
}

.no-results {
  color: #b8012d;
  flex: none;
}
</style>

<template>
  <page-with-footer>
    <template v-slot:content>
      <page-header class="default-gutter mb-6"/>
      <page-breadcrumbs/>
      <contact-us-container/>
    </template>
  </page-with-footer>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import PageWithFooter from "@/components/PageWithFooter";
  import ContactUsContainer from "@/components/contact-us/ContactUsContainer";
  import PageBreadcrumbs from "@/components/PageBreadcrumbs";

  export default {
    components: {
      PageBreadcrumbs,
      ContactUsContainer,
      PageWithFooter,
      PageHeader
    },
    mounted() {
      this.$breadcrumbs.clear();
      this.$breadcrumbs.pushRoute({path: '/'}, 'Home', false);
      this.$breadcrumbs.pushRoute({path: '/contact-us'}, 'Contact Us', true);
    }
  }
</script>

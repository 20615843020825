<template>
  <page-with-footer>
    <template v-slot:content>
      <page-header class="default-gutter mb-6"/>
      <page-breadcrumbs/>
      <v-container fluid class="default-gutter" >
        <evidentiary-review />
      </v-container>
    </template>
  </page-with-footer>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import PageWithFooter from "@/components/PageWithFooter";
  import PageBreadcrumbs from "@/components/PageBreadcrumbs";
  import EvidentiaryReview from "@/components/documents/EvidentiaryReview";

  export default {
    components: {
      EvidentiaryReview,
      PageBreadcrumbs,
      PageWithFooter,
      PageHeader,
    },
    mounted() {
      this.$breadcrumbs.clear();
      this.$breadcrumbs.pushRoute({path: '/'}, 'Home', false);
      this.$breadcrumbs.pushRoute({path: '/evidentiary-review'}, 'Evidentiary Review', true);
    }
  }
</script>

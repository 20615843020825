<template>
  <PostSearchResultList :posts="posts" class="background-result-list" @clicked="handleClick" :columns="3"/>
</template>

<script>
  import PostSearchResultList from '@/components/search/posts/PostSearchResultList';

  export default {
    props: {
      posts: {
        type: [],
        default: [],
        required: true
      },
    },
    components: {
      PostSearchResultList,
    },
    methods: {
      handleClick: function(post) {
        let route = {
          name: 'search-references-detail',
          params: {
            id: post.context_id
          }
        }

        if (this.$route.query.q || this.$route.query.t) {
          route.query = {
            q: this.$route.query.q,
            t: this.$route.query.t
          }
        }

        this.$router.push(route);
      }
    }
  }
</script>

<template>
  <page-with-footer>
    <template v-slot:content>
      <PageHeader class="default-gutter"/>
      <GlobalSearchBar class="default-gutter mt-6 mb-4" @selected="selected" :initial-term="term"/>
      <PageBreadcrumbs class="mb-8"/>
      <GlobalSearchResults
          v-if="term?.id && !noResults" :term="term"
          @searching="searching = true;"
          @completed="searching = false"
          @no-results="noResults = true"
          class="default-gutter"
      />
      <SearchLoading v-if="searching"/>
      <template v-if="!term?.id || noResults">
        <GlobalSearchNoResults class="default-gutter" :term="term" />
        <LandingCards class="default-gutter" />
      </template>
    </template>
  </page-with-footer>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import PageWithFooter from "@/components/PageWithFooter";
  import PageBreadcrumbs from "@/components/PageBreadcrumbs"
  import GlobalSearchBar from "@/components/search/global/GlobalSearchBar";
  import GlobalSearchNoResults from "@/components/search/global/GlobalSearchNoResults.vue";
  import SearchLoading from "@/components/search/SearchLoading.vue";
  import GlobalSearchResults from "@/components/search/global/GlobalSearchResults.vue";
  import LandingCards from "@/components/LandingCards";


  export default {
    components: {
      GlobalSearchResults,
      SearchLoading,
      GlobalSearchNoResults,
      PageWithFooter,
      PageHeader,
      PageBreadcrumbs,
      GlobalSearchBar,
      LandingCards
    },
    data() {
      return {
        searching: false,
        noResults: false
      }
    },
    computed: {
      term: function () {
        return {
          name: this.$route?.query?.q,
          id: this.$route?.query?.t
        }
      }
    },
    methods: {
      selected: function (item) {
        if (item === null) {
          return;
        }

        this.$gtag.event('view_search_results', {
          search_term_id: item?.id ?? '',
          search_term: item?.name ?? '',
          search_type: 'global'
        });

        this.$router.push({
          path: '/search',
          query: {
            q: item?.name,
            t: item?.id
          }
        }).catch(() => {
        });
      },
      updateBreadcrumbs: function (item) {
        this.$breadcrumbs.clear();
        this.$breadcrumbs.pushRoute({path: '/'}, 'Home', false);
        if (item) {
          this.$breadcrumbs.pushRoute(this.$route, item.name, true);
        }
      }
    },
    watch: {
      term: {
        handler: function(term) {
          this.updateBreadcrumbs(term);
        },
        deep: true
      }
    },
    beforeMount() {
      this.updateBreadcrumbs(this.term);
    }
  }
</script>

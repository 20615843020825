import {defineStore} from 'pinia'
import {v4 as UUIDv4} from "uuid";

export const alertsStore = defineStore('alerts', {
    state: () => ({
        items: [],
        defaults: {
            message: "",
            color: "",
            key: null,
            top: true,
            right: false,
            left: false,
            bottom: false,
            transition: "fade-transition",
            timeout: -1,
            show: true,
            icon: null,
            dismissible: true
        }
    }),
    actions: {
        /**
         *
         * @param item
         */
        push: function (item) {
            this.items.push(item);
            return item.key;
        },
        success: function (message, options = {}) {
            options.timeout = options.timeout ?? 5000;
            options = this.withDefaults(options);
            options.message = message;
            options.key = options.key ?? UUIDv4();
            options.icon = {
                name: 'mdi-check-circle',
                size: 21,
                color: '#45B98A'
            };
            return this.push(options);
        },
        error: function(message, options = {}) {
            options = this.withDefaults(options);
            options.message = message;
            options.key = options.key ?? UUIDv4();
            options.icon = {
                name: 'mdi-alert-circle-outline',
                size: 21,
                color: '#B8012D'
            };
            return this.push(options);
        },
        warning: function(message, options = {}) {
            options.timeout = options.timeout ?? 10000;
            options = this.withDefaults(options);
            options.message = message;
            options.key = options.key ?? UUIDv4();
            options.icon = {
                name: 'mdi-alert-outline',
                size: 21,
                color: '#EDA341'
            };
            return this.push(options);
        },
        update: function (key, message = "", options = {}) {
            let item = this.getByKey(key);
            if (typeof item === 'undefined') {
                return false;
            }
            item = {...item, ...options};
            item.message = message;
            return true;
        },
        close: function (key) {
            let item = this.getByKey(key);
            item.show = false;
        },
        clear: function () {
            this.items.forEach(item => item.show = false);
        },
        withDefaults: function(options) {
            return { ...this.defaults, ...options}
        },
        getByKey: function(key) {
            return this.items.find(item => item.key === key);
        }
    },
})

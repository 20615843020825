<template>
  <div>
    <slot v-if="hasPermission === false" name="permission-denied"></slot>
    <slot v-else></slot>
  </div>
</template>

<script>
  export default {
    props: {
      hasPermission: {
        type: Boolean,
        default: false
      },
    },
  }
</script>

<style></style>
<template>
  <auth-page>
    <login-form
        class="login"
        :route-params="routeParams"
        :route-path="routePath"
        :route-query="routeQuery"
    />
  </auth-page>
</template>

<script>
  import LoginForm from "@/components/auth/LoginForm";
  import AuthPage from "@/components/auth/AuthPage";

  export default {
    components: {AuthPage, LoginForm},
    props: {
      routePath: {
        type: String,
        default: '/',
      },
      routeParams: {
        type: Object,
        default: () => {
          return {}
        }
      },
      routeQuery: {
        type: Object,
        default: () => {
          return {}
        }
      }
    }
  }
</script>

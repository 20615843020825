<template>
  <v-container fluid class="pa-0">
    <PostContent :post="post">
      <template v-slot:append-end-content>
        <PermissionValidatorContainer :has-permission="$currentUser.loggedIn()">
          <CaseProcessingChangeSummarySection :case-processing-id="post.id"/>
        </PermissionValidatorContainer>
      </template>
    </PostContent>
  </v-container>
</template>

<script>

  import PostContent from '@/components/posts/PostContent';
  import PermissionValidatorContainer from "@/components/permissions/PermissionValidatorContainer";
  import CaseProcessingChangeSummarySection from "@/components/posts/case-processing/CaseProcessingChangeSummarySection";

  export default {
    props: {
      post: {
        default: {},
        required: true
      }
    },
    components: {
      PostContent,
      PermissionValidatorContainer,
      CaseProcessingChangeSummarySection
    },
  }
</script>

<style scoped>

</style>
<template>
  <VContainer fluid>
    <VRow dense class="align-center">
      <button class="cta cta-gradient" @click="$ctaModal.show">Gain Access</button>
      <div class="vertical-divider"/>
      <div class="cta-login">
        Already subscribed?
        <LoginLink class="cta-login"/>
      </div>
    </VRow>
  </VContainer>
</template>

<script>

  import LoginLink from '../router-links/LoginLink';

  export default {
    components: {
      LoginLink,
    },
  }
</script>

<style scoped>
  button.cta {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 23px 108px;
    text-transform: uppercase;
  }

  div.cta-login, a.cta-login {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0B578B;
  }

  div.vertical-divider {
    border: 1px solid #0B578B;
    transform: rotate(90deg);
    width: 26px;
    height: 0;
    margin: 0 19px 0 19px;
  }

  div.cta-login, a.cta-login {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0B578B;
  }

  a.cta-login:hover {
    opacity: 0.75;
  }
</style>

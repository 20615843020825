<template>
  <v-container fluid class="pa-0">
    <PostContent :post="post"/>
  </v-container>
</template>

<script>

  import PostContent from '@/components/posts/PostContent.vue';

  export default {
    props: {
      post: {
        default: {},
        required: true
      }
    },
    components: {
      PostContent,
    },
  }
</script>

<style scoped>

</style>
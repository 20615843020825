<template>
  <PageWithFooter>
    <template v-slot:content>
      <VContainer fluid class="default-gutter">
        <VRow>
          <PageHeader/>
          <BackgroundsPageBreadcrumbs
            class="mb-6"
            :item="initialTerm"
          />
          <BackgroundSearchHeader class="mb-10"/>
          <BackgroundSearchBar
            @searchByTerm="search"
            :initial-term="initialTerm"
          />
          <ContextContainer/>
        </VRow>
      </VContainer>
    </template>
  </PageWithFooter>
</template>

<script>

  import PageWithFooter from '@/components/PageWithFooter';
  import PageHeader from '@/components/PageHeader';
  import BackgroundsPageBreadcrumbs from '@/components/posts/backgrounds/BackgroundsPageBreadcrumbs';
  import BackgroundSearchHeader from '@/components/search/backgrounds/BackgroundSearchHeader';
  import BackgroundSearchBar from '@/components/search/backgrounds/BackgroundSearchBar';
  import ContextContainer from "@/components/context/ContextContainer.vue";

  export default {
    data() {
      return {
        context: null,
        initialTerm: null
      }
    },
    computed: {
      contextId() {
        return this.$route.params.id
      },
      searchTermName() {
        return this.$route.query.q;
      },
      searchTermId() {
        return this.$route.query.t;
      }
    },
    methods: {
      search(term) {
        this.$router.push({
          name: 'search-references',
          query: {
            q: term.name,
            t: term.id
          }
        })
      }
    },
    beforeMount: function() {
      if (this.searchTermName) {
        this.initialTerm = {
          name: this.searchTermName,
          id: this.searchTermId
        }
      }
    },
    components: {
      PageWithFooter,
      PageHeader,
      BackgroundsPageBreadcrumbs,
      BackgroundSearchHeader,
      BackgroundSearchBar,
      ContextContainer
    },
  }
</script>

<style scoped>
.custom-breadcrumb {
  margin: 0 -100px;
}
</style>
import {WordpressApiService} from "@/services/wordpress/api";

export class MemberpressApiService extends WordpressApiService {
    mpUrl = "wp-json/mp/v1";

    constructor() {
        super('');
    }

    me() {
        return this.get(`${this.mpUrl}/me`);
    }
}

<template>
  <PageWithFooter>
    <template v-slot:content>
      <v-container fluid class="default-gutter">
        <v-row class="flex-column">
          <PageHeader/>
          <BackgroundsPageBreadcrumbs
            class="mb-8"
            :item="searchTerm"
          />
          <BackgroundSearchHeader
            class="mb-10"
          />
          <BackgroundSearchBar
            :total-result="totalPosts"
            :initial-term="searchTerm"
            @searchByTerm="updateUrlQuery"
          />
          <SearchTotalResultDescription :total-result="totalPosts" />
          <BackgroundSearchLoading
            v-if="(isRunningSearch && !noResultsFound)"
            class="mb-12"
          />
          <BackgroundSearchResultList
            v-if="(!isRunningSearch && !noResultsFound)"
            :posts="posts"
            class="mb-10"
          />

          <SearchPagination
            v-if="showPagination"
            :page="parseInt(searchPage)"
            :items-per-page="postsPerPage"
            :total-result="totalPosts"
            @changePage="updateUrlQuery"
            class="mb-14"
          />

          <SearchNoResults
            v-if="(!isRunningSearch && noResultsFound)"
            :ring="true"
          />
        </v-row>
      </v-container>
    </template>
  </PageWithFooter>
</template>

<script>

  import PageWithFooter from '@/components/PageWithFooter';
  import PageHeader from '@/components/PageHeader';
  import BackgroundsPageBreadcrumbs from '@/components/posts/backgrounds/BackgroundsPageBreadcrumbs';
  import BackgroundSearchHeader from '@/components/search/backgrounds/BackgroundSearchHeader';
  import BackgroundSearchBar from '@/components/search/backgrounds/BackgroundSearchBar';
  import SearchTotalResultDescription from '@/components/search/SearchTotalResultDescription';
  import BackgroundSearchLoading from "@/components/search/backgrounds/BackgroundSearchLoading";
  import BackgroundSearchResultList from '@/components/search/backgrounds/BackgroundSearchResultList';
  import SearchPagination from '@/components/search/SearchPagination';
  import SearchNoResults from "@/components/search/SearchNoResults";
  import { backgroundsStore } from '@/store/backgrounds';
  import { PostSearch } from '@/mixins/PostSearch';

  export default {
    data() {
      return {
        scrollToElement: 'pagination',
      }
    },
    mixins: [PostSearch],
    beforeMount: function() {
      this.setSearchTerm();
      this.search();
    },
    computed: {
      store: () => backgroundsStore(),
    },
    components: {
      PageWithFooter,
      PageHeader,
      BackgroundsPageBreadcrumbs,
      BackgroundSearchHeader,
      BackgroundSearchBar,
      SearchTotalResultDescription,
      BackgroundSearchLoading,
      BackgroundSearchResultList,
      SearchPagination,
      SearchNoResults
    },
  }
</script>

<style scoped>
.custom-breadcrumb {
  margin: 0 -100px;
}
</style>

<template>
  <PageWithFooter>
    <template v-slot:content>
      <v-container fluid class="default-gutter">
        <v-row>
          <PageHeader/>
          <PostSearchBreadcrumbs :route="breadcrumbsRoute" :item="term" class="mb-8"/>
          <GuidelinesSearchHeader class="mb-10"/>
          <PostSearchBar :initial-term="term" @searchByTerm="search">
            <template v-slot:placeholder>
              <span class="search-label">Search for <b>Coverage Guidelines</b> by Disease names, ICD-10 Codes, CPT® Codes, Genes</span>
            </template>
          </PostSearchBar>
          <GuidelinePostContent :post="guideline" class="mt-8"/>
        </v-row>
      </v-container>
    </template>
    <template v-slot:footer>
      <cta-sign-up-banner v-if="!$currentUser.loggedIn()"/>
      <page-footer/>
    </template>
  </PageWithFooter>
</template>

<script>
  import PageHeader from "@/components/PageHeader";
  import PostSearchBar from '@/components/search/posts/PostSearchBar';
  import PageWithFooter from "@/components/PageWithFooter";
  import GuidelinesSearchHeader from '@/components/search/guidelines/GuidelinesSearchHeader';
  import GuidelinePostContent from "@/components/posts/guideline/GuidelinePostContent";
  import CtaSignUpBanner from "@/components/call-to-action/CtaSignUpBanner";
  import PageFooter from "@/components/PageFooter";
  import {guidelinesStore} from "@/store/guidelines";
  import PostSearchBreadcrumbs from "@/components/search/posts/PostSearchBreadcrumbs.vue";

  export default {
    data() {
      return {
        guideline: {
          title: '',
          content: '',
          preview: ''
        },
        breadcrumbsRoute: {
          name: 'search-guidelines',
          description: 'Coverage Guidelines',
          path: '/search/guidelines'
        },
      }
    },
    computed: {
      guidelines: () => guidelinesStore(),
      guidelineId() {
        return this.$route.params.id;
      },
      term() {
        if (this.$route?.query?.t || this.$route?.query?.q) {
          return {
            id: this.$route?.query?.t ?? null,
            name: this.$route?.query?.q ?? null
          };
        }
        return null;
      }
    },
    methods: {
      search(term) {
        this.$router.push({
          name: 'search-guidelines',
          query: {
            q: term.name,
            t: term.id
          }
        });
      },
    },
    beforeMount: function () {
      this.guidelines.get(this.guidelineId)
          .then(guideline => {

            // if the guideline is archived, redirect to the archived guideline view
            if (guideline?.archived) {
              return this.$router.push({name: 'guidelines-previous-versions', params: {id: guideline.id}}).then(() => {
                this.$alerts.warning('This guideline is archived.', {timeout: 5000})
              });
            }

            if (guideline === undefined) {
              return this.$router.replace({name: 'page-not-found'});
            }

            this.guideline = guideline;
            this.$breadcrumbs.enableLast();
            this.$breadcrumbs.pushRoute({path: '/search/guidelines/'}, guideline.title, true);
          })
    },
    components: {
      PostSearchBreadcrumbs,
      PageFooter,
      CtaSignUpBanner,
      GuidelinePostContent,
      GuidelinesSearchHeader,
      PostSearchBar,
      PageWithFooter,
      PageHeader,
    }
  }
</script>

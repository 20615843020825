<template>
  <PostSearchHeader>
    <template v-slot:header-title>
      CPT® Code Insights
    </template>
    <template v-slot:header-description>
      CPT® Code insights to help decipher molecular code descriptions and applications.
    </template>
  </PostSearchHeader>
</template>

<script>
  import PostSearchHeader from '@/components/search/posts/PostSearchHeader';

  export default {
    components: {
      PostSearchHeader,
    },
  }
</script>
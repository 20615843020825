<template>
  <v-container fluid class="pa-0 mb-10 change-summary-data-table">
    <div class="overflow-x-auto" style="width: 88.33vw;">
      <v-data-table
        :headers="headers"
        :items="formattedChanges"
        :items-per-page="itemsPerPage"
        :hide-default-header="!hasChanges"
        hide-default-footer
        disable-sort
      >

      <template #[`item.content`]="{value}">
        <span v-html="value"></span>
      </template>

      </v-data-table>
    </div>
  </v-container>
</template>

<script>

  export default {
    data() {
      return {
        headers: [
          {
            text: 'Updated On',
            value: 'date',
            width: 143
          },
          {
            text: 'Description of changes',
            value: 'content',
          },
        ],
      }
    },
    props: {
      itemsPerPage: {
        type: Number,
        default: 10
      },
      isLoading: {
        type: Boolean,
        default: true
      },
      changes: {}
    },
    computed: {
      hasChanges() {
        return this.changes?.length > 0;
      },
      formattedChanges() {
        return this.changes.map((change) => {

          const year = change.date.substr(0, 4);
          const month = change.date.substr(4, 2);
          const day = change.date.substr(6, 2);

          return {
            content: change.content,
            date: month + '/' + day + '/' + year
          };
        });
      }
    },
  }
</script>

<style scoped>
  .change-summary-data-table :deep(table) {
    border-collapse: collapse !important;
    border: 2px solid #FFFFFF !important;
  }

  .change-summary-data-table :deep(th) {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #FFFFFF !important;
    border: 2px solid #FFFFFF !important;
    background: #45B98A !important;
    height: 52px !important;
  }

  .change-summary-data-table :deep(th:nth-child(1)), :deep(td:nth-child(1)) {
    text-align: center !important;
  }
  .change-summary-data-table :deep(td) {
    font-family: 'Work Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    padding: 16px !important;

    border: 2px solid #FFFFFF !important;
    color: #000000 !important;
  }

  .change-summary-data-table :deep(td > span > p), .change-summary-data-table :deep(td > span > ul), .change-summary-data-table :deep(td > span > ol) {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .change-summary-data-table :deep(tr:nth-child(even)) {
    background: #E0DFDE !important;
  }

  .change-summary-data-table :deep(tr:nth-child(odd)) {
    background: #FFFFFF !important;
  }

  .change-summary-data-table :deep(tr > td:nth-child(n + 3)) {
    border-top: none !important;
    border-bottom: none !important;
  }

  .change-summary-data-table :deep(tr:nth-child(odd) > td:nth-child(odd)) {
    border-right: 2px solid #E0DFDE !important;
  }

  .change-summary-data-table :deep(.primary){
    background-color: #EDA341 !important;
    border-color: #EDA341 !important;
  }
</style>

<template>
  <v-container>
    <v-row align-content="center">
      <v-col>
        <v-container class="form-container">
          <v-row class="mb-8">
            <v-col>
              <v-row class="align-center justify-start mb-5">
                <v-img src="@/assets/icon-email-sent.svg" max-height="48px" max-width="48px"/>
                <h2 class="ml-2">Check your email!</h2>
              </v-row>
              <v-row class="mb-4">
                <span class="subtitle">If an active account with your email is found, you will receive password recovery instructions.</span>
              </v-row>
              <v-row class="mb-4">
                <span class="help">Not receiving our email? It could be that you typed your email incorrectly or you are not on file with us.</span>
              </v-row>
              <v-row>
                <button class="help" @click="$router.push({path: '/contact-us'})">
                  Click here to get help.
                </button>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0">
              <v-btn
                  class="send active rounded-sm no-uppercase"
                  text x-large
                  @click="sendEmail()"
              >
                Re-send Instructions
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {passwordResetStore} from "@/store/password-reset";

  export default {
    computed: {
      passwordReset: () => passwordResetStore()
    },
    methods: {
      sendEmail: async function () {
        const response = await this.passwordReset.sendResetEmail();
        if (response) {
          this.$emit('sent', this.passwordReset.email);
        } else {
          // handle error
        }
        return response;
      }
    }
  }
</script>

<style scoped>
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #004568;
  }

  .subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #004568;
  }

  .form-container {
    background-color: #FFFFFF;
    box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    margin: 30px auto;
    max-width: 770px;
    padding: 40px 56px;
  }

  .send {
    width: 100%;
    padding: 27px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    background-color: #EEEEEE;
  }

  .send.active {
    background-color: #EDA341;
    color: #FFFFFF;
  }

  .send.active:hover {
    opacity: 0.7;
  }

  .send {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #888888;
  }

  button.help {
    color: #EDA341;
    text-decoration-line: underline;
  }

  .no-uppercase {
    text-transform: unset !important;
  }
</style>
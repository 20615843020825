<template>
  <SearchLoading class="loading-backgrounds py-0"/>
</template>

<script>
  import SearchLoading from "@/components/search/SearchLoading";

  export default {
    components: {
      SearchLoading,
    },
  }
</script>

<style scoped>
.loading-backgrounds {
  height: 505px;
}
</style>

<template>
  <PageWithFooter>
    <template v-slot:content>

      <GuidelinePreviousVersionsHeader/>

      <v-container fluid>
        <v-layout class="default-gutter d-inline-block">
          <h1 class="pl-3 mb-6">Effective dates: {{ getFormattedEffectiveDates }}</h1>
          <hr class="mb-12">
          <PostContent :post="post"/>
        </v-layout>
      </v-container>

    </template>
  </PageWithFooter>
</template>

<script>

  import { guidelinesStore } from '@/store/guidelines';
  import { DateTime } from 'luxon';

  import PageWithFooter from '@/components/PageWithFooter';
  import GuidelinePreviousVersionsHeader from '@/components/posts/guideline/GuidelinePreviousVersionsHeader';
  import PostContent from "@/components/posts/PostContent";

  export default {
    data() {
      return {
        post: []
      }
    },
    computed: {
      guidelineId() {
        return this.$route.params.id;
      },
      getFormattedEffectiveDates() {
        return this.formatEffectiveDates(
          this.post.effective_start_date,
          this.post.effective_end_date
        );
      }
    },
    methods: {
      getArchivedGuideline() {
        guidelinesStore()
        .get(this.guidelineId)
        .then((guideline) => {

          if (guideline === undefined) {
            return this.$router.replace({name: 'page-not-found'});
          }

          // if the guideline is not archived, redirect to the guideline view
          if (!guideline.archived) {
            return this.$router.push({name: 'guidelines', params: {id: guideline.id}}).then(() => {
              this.$alerts.warning('This guideline is effective.', {timeout: 5000})
            });
          }


          this.post = guideline;
         });
      },
      formatEffectiveDates(startDate, endDate) {

        if (!startDate && !endDate) {
          return '';
        }

        const start = DateTime.fromISO(startDate).toFormat('LL/dd/yyyy');
        const end = DateTime.fromISO(endDate).toFormat('LL/dd/yyyy');

        return start + ' - ' + end;
      },
    },
    beforeMount: function () {

      if(!this.$currentUser.loggedIn()) {
          return this.$router.push({name: 'login', params: {routePath: this.$route.path, routeParams: {...this.$route.params}, routeQuery: {...this.$route.query}}}).then(() => {
          this.$alerts.error('Please log in to access the archived Coverage Guideline.', {timeout: 5000})
        });
      }

      this.getArchivedGuideline();
    },
    components: {
      PageWithFooter,
      GuidelinePreviousVersionsHeader,
      PostContent
    },
  }
</script>

<style scoped>
h1 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #504E4E;
}

hr {
  border: none;
  border-bottom: 1px solid #000000;
  margin-left: -100px;
  max-width: 520px;
}
</style>

<template>
  <v-overlay opacity="0.50" color="#000000" class="cta-overlay" :dark="false" :value="$ctaModal.display" >
    <cta-form @close="() => $ctaModal.hide()" />
  </v-overlay>
</template>

<script>
  import CtaForm from "@/components/call-to-action/CtaForm";

  export default {
    components: {CtaForm}
  }
</script>
export const PostSearch = {
    data() {
        return {
            posts: [],
            totalPosts: 0,
            postsPerPage: 36,
            isRunningSearch: true,
            isRunningPageChange: false,
        }
    },
    computed: {
        noResultsFound() {
            return this.totalPosts === 0;
        },
        searchPage() {
            return parseInt(this.$route.query.page ?? 1);
        },
        searchPerPage() {
            return parseInt(this.$route.query.per_page ?? this.postsPerPage);
        },
        searchTermName() {
            return this.$route.query.q;
        },
        searchTermId() {
            return this.$route.query.t;
        },
        showPagination() {
            return (!this.isRunningSearch || this.isRunningPageChange) && this.hasMoreThanOnePage && !this.noResultsFound;
        },
        routePath: function () {
            return this.$router.history.current.path
        },
        hasMoreThanOnePage() {
            return (this.totalPosts > this.searchPerPage) && (this.searchPerPage !== -1);
        }
    },
    methods: {
        search() {

            this.toggleRunningSearch(true);

            const query = this.setUrlQuery();

            // if the term doesn't exist then return no results
            if (query.q && !query.t && (this.$options.name !== 'CPTCodeSearchView')) {
                return this.toggleNoResultsFound();
            }

            this.store?.search(query)
                .then((response) => {
                    this.posts = response.data;
                    this.totalPosts = response.total;
                    this.toggleRunningSearch(false);
                    this.toggleRunningPageChange(false);
                    this.paginationKey = Date.now();
                })
                .catch(() => {
                    this.toggleNoResultsFound();
                });
        },
        updateUrlQuery(params) {
            let query = this.setUrlQuery(params);

            // when search by term or update items per page starts at page 1
            if (params?.name || params?.id || params.per_page) {
                query.page = 1;
            }

            let route = {
                path: this.routePath,
                query: query,
            };

            this.$router.push(route).catch(() => {});
        },
        setUrlQuery(params) {
            // setting to empty string if theres no id so it be part of the url
            if (params?.name && !params?.id) {
                params.id = '';
            }

            let q = params?.name === undefined ? this.searchTermName : params?.name;
            let t = params?.id === undefined ? this.searchTermId : params?.id;
            let page = params?.page === undefined ? this.searchPage : params?.page;
            let per_page = params?.per_page === undefined ? this.searchPerPage : params?.per_page;
            let additionalParams = typeof this.$route.query === 'object' ? Object.fromEntries(
                Object.entries(this.$route.query)
                    .filter(([key]) => !['q', 't', 'page', 'per_page'].includes(key))
            ) : {};

            return {
                ...(q ? {q} : {}),
                ...(t ? {t} : {}),
                ...(page ? {page} : {}),
                ...(per_page ? {per_page} : {}),
                ...additionalParams
            };
        },
        toggleRunningSearch(status) {
            this.isRunningSearch = status;
        },
        toggleRunningPageChange(status) {
            this.isRunningPageChange = status;
        },
        toggleNoResultsFound() {
            this.toggleRunningSearch(false);
            this.posts = [];
            this.totalPosts = 0;
        },
        setSearchTerm() {
            this.searchTerm = null;

            if (!this.searchTermName) {
                return;
            }

            this.searchTerm = {
                name: this.searchTermName,
                id: this.searchTermId
            };
        }
    },
    watch: {
        '$route'(to, from) {
            if (to === from) {
                return;
            }

            if (to.query?.page !== from.query?.page) {
                this.toggleRunningPageChange(true);
            }

            this.setSearchTerm();
            this.search();
        }
    }
}
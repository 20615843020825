<template>
  <v-container fluid>
    <PageBreadcrumbs class="backgrounds-breadcrumbs"/>
  </v-container>
</template>

<script>
  import PageBreadcrumbs from '@/components/PageBreadcrumbs';
  export default {
    props: {
      item: {
        type: Object,
        default: () => {return {}}
      }
    },
    methods: {
      setBreadcrumbs: function (item) {
        item = item ?? {};
        this.$breadcrumbs.clear();
        this.$breadcrumbs.pushRoute({path: '/'}, 'Home');
        this.$breadcrumbs.pushRoute({path: '/search/references'}, 'Reference Library', !item?.id && !item?.name);
        if (item?.name) {
          this.$breadcrumbs.pushRoute({name: 'search-references', query: {q: item.name, t: item.id}}, item.name, true);
        }
      },
    },
    watch: {
      item: {
        handler: function(item) {
          this.setBreadcrumbs(item)
        },
        deep: true
      },
    },
    components: {
      PageBreadcrumbs,
    },
    beforeMount() {
      this.setBreadcrumbs(this.item);
    }
  }
</script>

<style scoped>
.backgrounds-breadcrumbs {
  margin: 0 -100px;
}
</style>
<template>
  <PageWithFooter>
    <template v-slot:content>
      <ChangeSummaryHeader/>
      <v-container fluid>
        <v-layout class="default-gutter d-inline-block">

          <SearchPerPage
            v-if="totalPosts > postsPerPage"
            :items-per-page="searchPerPage"
            @changePerPage="updateUrlQuery"
            class="mb-8"
          />

          <ChangeSummaryDataTable
            v-if="(!isRunningSearch && !noResultsFound)"
            :changes="posts"
            :items-per-page="searchPerPage"
          />
        </v-layout>

        <SearchLoading
          v-if="(isRunningSearch)"
        />

        <SearchPagination
          v-if="showPagination"
          :disabled="isRunningSearch"
          :page="searchPage"
          :items-per-page="searchPerPage"
          :total-result="totalPosts"
          @changePage="updateUrlQuery"
          class="mb-12"
        />

      </v-container>
    </template>
  </PageWithFooter>
</template>

<script>

  import { PostSearch } from '@/mixins/PostSearch';
  import { cptCodesChangeSummaryStore } from '@/store/cpt-codes-change-summary';

  import PageWithFooter from '@/components/PageWithFooter';
  import ChangeSummaryHeader from '@/components/cpt-codes/change-summary/ChangeSummaryHeader';
  import SearchPerPage from '@/components/search/results/SearchPerPage';
  import ChangeSummaryDataTable from '@/components/cpt-codes/change-summary/ChangeSummaryDataTable';
  import SearchPagination from '@/components/search/SearchPagination';

  import SearchLoading from '@/components/search/SearchLoading';

  export default {
    mixins: [PostSearch],
    data() {
      return {
        postsPerPage: 10
      }
    },
    computed: {
      store: () => cptCodesChangeSummaryStore()
    },
    beforeMount: function () {

      if (!this.$currentUser.loggedIn()) {
        return this.$router.push({name: 'login'}).then(() => {
          this.$alerts.error('Please login to access the CPT code module.', {timeout: 5000})
        });
      }

      this.search();
    },
    components: {
      PageWithFooter,
      ChangeSummaryHeader,
      SearchPerPage,
      ChangeSummaryDataTable,
      SearchPagination,
      SearchLoading
    },
  }
</script>

<style scoped>
.page-description {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.default-gutter{
  margin-top: 40px;
}
</style>
import {defineStore} from 'pinia'

export const guidelinesStore = defineStore('guidelines', {
    state: () => ({items: []}),
    actions: {
        async getByTerm(termId) {
            try {
                const paginator = await this.$wp.guidelines.paginate({per_page: -1, endpoint: `${termId}/by-term`});
                this.items = paginator.items;
                return paginator;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async get(id) {
            try {
                return await this.$wp.guidelines.read(id);
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async list() {
            const paginator = await this.$wp.guidelines.paginate({per_page: -1, endpoint: `/`});
            this.items = paginator.items;
            return paginator;
        },
        search(params) {
            if (params?.t) {
                return this.$wp.guidelines.read(params.t, 'by-term', params);
            }

            return this.$wp.guidelines.read(null, '', params);
        },
        getRevisionHistory(guideline_id) {
            return this.$wp.guidelines.read(guideline_id, 'revision-history');
        }
    }
})

<template>
  <v-container fluid>
    <v-layout align-center class="header default-gutter">
      <v-icon color="white" class="mr-2">mdi-history</v-icon>
      <div>CPT&reg; Code Insights: <span>Change Summary</span></div>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.header {
  background-color: #004568;
  color: white;
  height: 64px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  max-width: 100%;
}

.header span {
  font-weight: 400;
}
</style>
<template>
  <PageWithFooter>
    <template v-slot:content>
      <v-container fluid class="default-gutter">
        <v-row class="flex-column">
          <PageHeader/>
          <PostSearchBreadcrumbs :route="breadcrumbsRoute" :item="item" class="mb-8"/>
          <CPTCodesSearchHeader class="mb-16"/>
          <PermissionValidatorContainer :has-permission="$currentUser.loggedIn()">

            <template v-slot:permission-denied>
              <PostSearchNonLoggedContainer/>
            </template>

            <!-- Components allowed only to logged users must go here -->
            <CPTCodeSearchBar
                :initial-term="searchTerm"
                @search="updateUrlQuery"
                class="mb-10"
                :disabled="newestCodesOnly"
            />

            <v-container fluid class="mb-16">
              <v-row v-if="!noResults" dense class="d-flex">
                <div style="margin-right: 80px;">
                  <PostSearchTotalResult :total-result="totalPosts"/>
                </div>
                <div>
                  <NewestCodesOnly />
                </div>
                <div>
                  <SearchPerPage :items-per-page="searchPerPage" @changePerPage="updateUrlQuery"/>
                </div>
                <div class="ml-auto">
                  <ChangeSummaryButton :route="{name: 'cpt-codes-change-summary'}"/>
                </div>
              </v-row>
            </v-container>

            <ScrollToTopButton/>

            <CptCodesDataTable
                v-if="(!isRunningSearch && !noResultsFound)"
                :codes="posts"
                :search="searchTermName"
                :items-per-page="searchPerPage"
            />

            <SearchLoading
                v-if="(isRunningSearch && !noResultsFound)"
                class="mb-12"
            />

            <SearchNoResults
                v-if="noResults"
                :ring="true"
            />

            <v-container class="mb-13">
              <v-row align="center" justify="center">
                <SearchPagination
                    v-if="showPagination"
                    :disabled="isRunningSearch"
                    :page="searchPage"
                    :items-per-page="searchPerPage"
                    :total-result="totalPosts"
                    @changePage="updateUrlQuery"
                />
              </v-row>
            </v-container>

          </PermissionValidatorContainer>
        </v-row>
      </v-container>
    </template>
  </PageWithFooter>
</template>

<script>

  import PageWithFooter from '@/components/PageWithFooter';
  import PageHeader from '@/components/PageHeader';

  import PostSearchBreadcrumbs from '@/components/search/posts/PostSearchBreadcrumbs';
  import CPTCodesSearchHeader from '@/components/search/cpt-codes/CPTCodesSearchHeader';

  import PermissionValidatorContainer from '@/components/permissions/PermissionValidatorContainer';
  import PostSearchNonLoggedContainer from '@/components/search/posts/PostSearchNonLoggedContainer';

  import CPTCodeSearchBar from '@/components/search/cpt-codes/CPTCodeSearchBar';
  import PostSearchTotalResult from '@/components/search/posts/PostSearchTotalResult';
  import NewestCodesOnly from "@/components/cpt-codes/NewestCodesOnly";
  import SearchPerPage from '@/components/search/results/SearchPerPage';
  import ChangeSummaryButton from '@/components/buttons/ChangeSummaryButton'

  import ScrollToTopButton from '@/components/buttons/ScrollToTopButton';

  import CptCodesDataTable from '@/components/cpt-codes/CptCodesDataTable';

  import SearchLoading from '@/components/search/SearchLoading';
  import SearchNoResults from '@/components/search/SearchNoResults';

  import SearchPagination from '@/components/search/SearchPagination';

  import {PostSearch} from '@/mixins/PostSearch';
  import {cptCodesStore} from '@/store/cpt-codes';

  export default {
    name: 'CPTCodeSearchView',
    mixins: [
      PostSearch
    ],
    data() {
      return {
        breadcrumbsRoute: {
          name: 'search-cpt-codes',
          description: 'CPT® Code Insights',
          path: '/search/cpt-codes'
        },
        postsPerPage: 10,
      }
    },
    beforeMount: function () {
      this.setSearchTerm();
      this.search();
    },
    computed: {
      store: () => cptCodesStore(),
      newestCodesOnly: function() {
        return this.$route.query.newest_codes === 'true';
      },
      searchTermName: function () {
          return this.newestCodesOnly ? this.store.quarterDate : this.$route.query.q;
      },
      item: function () {
        return this.searchTermName ? {
          name: this.searchTermName,
          id: null
        } : null;
      },
      noResults: function() {
        return !this.isRunningSearch && this.noResultsFound;
      }
    },
    components: {
      PageWithFooter,
      PageHeader,
      PostSearchBreadcrumbs,
      CPTCodesSearchHeader,
      PermissionValidatorContainer,
      PostSearchNonLoggedContainer,
      CPTCodeSearchBar,
      PostSearchTotalResult,
      NewestCodesOnly,
      SearchPerPage,
      ChangeSummaryButton,
      ScrollToTopButton,
      CptCodesDataTable,
      SearchLoading,
      SearchNoResults,
      SearchPagination,
    },
  }
</script>


<template>
  <v-container class="contact-us-info pa-0 pr-16">
    <v-row class="pa-0">
      <v-col class="pa-0">
        <h2>Contact Us</h2>
        <hr/>
      </v-col>
    </v-row>
    <v-row>
      <p>
        Fill in the form and our team will get in touch with you as soon as possible!
      </p>
    </v-row>
    <v-row>
      <p>
        To learn more about InformedDNA® please visit us at any of the following:
      </p>
    </v-row>
    <v-row class="mt-10">
      <template v-for="(icon, index) in icons" >
        <v-btn
            :key="`icons_${index}`"
            icon
            :ripple="false"
            :href="icon.url"
            target="_blank"
            class="social-icon"
        >
          <v-icon color="#FFFFFF" size="40">{{icon.name}}</v-icon>
        </v-btn>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        icons: [
          {
            name: 'mdi-web',
            url: 'https://informeddna.com/'
          },
          {
            name: 'mdi-linkedin',
            url: 'https://www.linkedin.com/company/informeddna/'
          },
          {
            name: 'mdi-facebook',
            url: 'https://www.facebook.com/Informeddnageneticexperts/'
          },
          {
            name: 'mdi-twitter',
            url: 'https://twitter.com/informeddna'
          },
        ]
      }
    }
  }
</script>

<style scoped>
  .contact-us-info {
    color: #FFFFFF;
  }

  hr {
    background-color: #45B98A;
    border: 0;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    height: 6px;
    margin-left: -112px;
    width: 75%;
  }

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
  }

  p {
    margin-top: 24px;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }

  .social-icon {
    margin-right: 42px;
  }

  :deep(a.social-icon) :hover {
    color: rgba(255, 255, 255, 0.5) !important;
  }

</style>
import { defineStore } from 'pinia';

export const searchCPTCodes = defineStore('search-cpt-codes', {
    state: () => ({
        items: [],
        searched: null,
        selected: null,
    }),
    actions: {
        async list(query) {
            try {
                const result = await this.$wp.cptCodes.read(null, 'autocomplete', {q: query});
                this.items = result.map((match => (
                    {
                        name: match,
                        id: Math.floor(Math.random() * 100)
                    }
                )));
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async get(name) {
            return await this.$wp.searchTerms.read(null, 'by-name', {q: name});
        },
        reset() {
            this.items = [];
            this.searched = null;
            this.selected = null;
        }
    },
})


<template>
  <page-with-footer>
    <template v-slot:content>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            <slot name="logo">
              <v-img src="@/assets/logo-informeddna-white.png" class="logo"/>
            </slot>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <slot name="default"></slot>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:footer>
      <v-footer class="footer">
        <v-row>
          <v-col align="center" class="copyright">
            <slot name="footer">
              Copyright © 2023 InformedDNA®
            </slot>
          </v-col>
        </v-row>
      </v-footer>
    </template>
  </page-with-footer>
</template>

<script>
  import PageWithFooter from "@/components/PageWithFooter";
  export default {
    components: {PageWithFooter},
    mounted() {
      // don't like this but need this background to go behind our semi-transparent footer.
      window.document.getElementById('app').classList.add('auth-background');
    },
    destroyed() {
      window.document.getElementById('app').classList.remove('auth-background');
    }
  }
</script>

<style scoped>
  .footer {
    padding: 50px 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .logo {
    height: 72px;
    width: 285px;
    margin-top: 56px;
    margin-left: 112px;
  }

  .copyright {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
  }
</style>

<style>
  #app.auth-background {
    background: linear-gradient(0deg, rgba(0, 45, 68, 0.6), rgba(0, 45, 68, 0.6)), url('@/assets/background-dna.png');
    background-size: cover;
  }
</style>
<template>
  <v-container fluid>
    <v-row class="mb-0 section">
      <h2 class="section-text">{{ section }}</h2>
    </v-row>
    <v-row ref="postContent" class="mt-0 post-content">
      <component :is="PostComponentMap[post.type]" :post="post"/>
    </v-row>
  </v-container>
</template>

<script>
  import PostContent from "@/components/posts/PostContent";
  import BackgroundPostContent from "@/components/posts/backgrounds/BackgroundPostContent";
  import ReferencePostContent from "@/components/posts/references/ReferencePostContent";
  import CaseProcessingPostContent from "@/components/posts/case-processing/CaseProcessingPostContent";
  import GuidelinePostContent from "@/components/posts/guideline/GuidelinePostContent";

  export default {
    props: {
      section: {
        type: String,
        required: true
      },
      post: {
        default: {},
        required: true
      }
    },
    data() {
      return {
        PostComponentMap: {
          'background': BackgroundPostContent,
          'references': ReferencePostContent,
          'case-processing': CaseProcessingPostContent,
          'guideline': GuidelinePostContent
        }
      }
    },
    methods: {
      scrollToTop() {
        this.$refs.postContent.scrollTop = 0;
      }
    },
    watch: {
      post() {
        this.scrollToTop();
      },
    },
    components: {
      PostContent,
      BackgroundPostContent,
      ReferencePostContent,
      CaseProcessingPostContent,
      GuidelinePostContent,
    },
  }
</script>

<style scoped>
  .section {
    padding: 8px 32px;
    background: #004568;
  }

  .section-text {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
  }

  .post-content {
    overflow-y: auto;
    max-height: 65vh;
  }
</style>

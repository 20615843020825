<template>
  <v-container fluid class="pa-0">
    <HelpCenterContentItem>
      <h2>How do I choose the most appropriate search terms?</h2>

      <p>The best search terms are clinical cues from a medical record or requested test that align with the suggestions to consider (green font) in the left column of the table below. Potential search terms from three examples are also provided. Within each example, any of the search terms listed will lead you to the relevant content.</p>

      <SearchTermsHelpTable/>

      <p><span class="font-weight-bold">Helpful Hint:</span> Make yourself a cheat sheet with a similar checklist while you get comfortable navigating the platform:</p>

      <ul>
        <li>Have I searched by CPT code?  </li>
        <li>Have I searched by Test name?</li>
        <li>Have I searched by Disease name? </li>
      </ul>

      <p>One or all of these searches will lead you to the information you’re looking for!</p>

    </HelpCenterContentItem>

    <HelpCenterContentItem>
      <h2>What is a “Reference Library”?</h2>

      <p>A reference library is a trio of InformedGENOMICS™ content, i.e. the background, references and decision aid, for a specific condition or category of tests.</p>

      <p><span class="font-weight-bold">Helpful Hint: </span>Find the most appropriate reference library for your request and select “Read More” to enter and view all associated content. An index is available in each reference library to help you navigate the content.</p>
    </HelpCenterContentItem>

    <HelpCenterContentItem>
      <h2>What if I don’t see an Index?</h2>

      <p>If you don’t see the Index, you have yet to enter a specific reference library. After searching with a keyword, click on the “Read More” button under one of the resulting Reference Library categories that best aligns with your clinical query. Once selected, your view will change, and the Index panel will appear on the left hand side of the page.</p>
    </HelpCenterContentItem>

    <HelpCenterContentItem>
      <h2>How do I view the complete list of coverage guidelines, reference libraries, or CPT codes without narrowing my search by specific keywords?</h2>

      <p>Ensure you are on the InformedGENOMICS™ homepage and scroll to the bottom. You will see a green box for the Coverage Guidelines module (left), a blue box for the Reference Libraries module (center) and an orange box for the CPT Code Insights module (right). Click on “View” to be taken directly to the complete set of entries for each module.</p>

      <p><span>Helpful Hint: </span>While viewing the coverage guidelines, reference libraries or CPT codes, you can use the search bar within each module – it will function like a filter for that particular content.</p>
    </HelpCenterContentItem>

    <HelpCenterContentItem>
      <h2>How do I choose which content to start with?</h2>

      <ContentExplanationTable/>
    </HelpCenterContentItem>

    <HelpCenterContentItem>
      <h2>How do I start a new search?</h2>

      <p>Simply scroll up! Your search bar never disappears from the top of a page. Once you find the direction you need for one review, move on to the next query by simply scrolling up and entering in a new search term.</p>
    </HelpCenterContentItem>
  </v-container>
</template>

<script>

  import HelpCenterContentItem from '@/components/help-center/HelpCenterContentItem.vue';
  import SearchTermsHelpTable from '@/components/help-center/content-tables/SearchTermsHelperTable.vue';
  import ContentExplanationTable from '@/components/help-center/content-tables/ContentExplanationTable.vue';

  export default {
    components: {
      HelpCenterContentItem,
      SearchTermsHelpTable,
      ContentExplanationTable
    }
  }

</script>

<style scoped>
* {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}

p {
  margin: 16px 0 16px 0;
}

</style>
<template>
  <v-container fluid>
    <section-container v-if="!searchInProgress" title="Reference Library">
      <result-background
          v-for="(background, index) in backgrounds.items"
          :key="'background_' + index"
          :background="background"
          :onClick="() => clickBackground(background)"
      />
    </section-container>
    <section-container v-if="!searchInProgress" title="Coverage Guidelines">
      <result-guideline
          v-for="(guideline, index) in guidelines.items"
          :key="'guideline_' + index"
          :guideline="guideline"
          :onClick="() => clickGuideline(guideline)"
      />
    </section-container>
    <section-container v-if="!searchInProgress" title="CPT® Code Insights">
      <template v-if="$currentUser.loggedIn()">
        <div v-if="cptCodes.items.length" class="cpt-code-container">
          <result-cpt-codes :cptCodes="cptCodes.items"></result-cpt-codes>
        </div>
        <template v-else>
          <span class="no-cpts">Searching for content with no applicable CPT codes OR searching with Tier 2 codes (81400-81408) or codes considered out-of-scope by InformedGenomics™ will generate a blank CPT Code Insights Table. If searching with a generic Tier 2 code(s), please search using specific terms for gene(s)/condition(s).</span>
        </template>
      </template>
      <cta-cpt-codes v-else/>
    </section-container>
  </v-container>
</template>

<script>
  import SectionContainer from "@/components/search/results/SectionContainer";
  import ResultBackground from "@/components/search/results/ResultBackground";
  import ResultGuideline from "@/components/search/results/ResultGuideline";
  import ResultCptCodes from "@/components/search/results/ResultCptCodes";
  import {backgroundsStore} from "@/store/backgrounds";
  import {guidelinesStore} from "@/store/guidelines";
  import {cptCodesStore} from "@/store/cpt-codes";
  import CtaCptCodes from "@/components/call-to-action/CtaCptCodes";

  export default {
    components: {
      CtaCptCodes,
      ResultGuideline,
      ResultBackground,
      ResultCptCodes,
      SectionContainer,
    },
    data() {
      return {
        resultsCompleted: 0,
        searchInProgress: true,
      }
    },
    props: {
      term: {
        required: true
      }
    },
    computed: {
      backgrounds: () => backgroundsStore(),
      guidelines: () => guidelinesStore(),
      cptCodes: () => cptCodesStore(),
    },
    methods: {
      search: function (termId) {
        this.resetSearch();
        if (!termId) {
          this.$emit('completed');
          this.$emit('no-results');
          return;
        }
        this.$emit('searching');
        this.backgrounds.getByTerm(termId).then(() => this.updateResults());
        this.guidelines.getByTerm(termId).then(() => this.updateResults());
        if (this.$currentUser.loggedIn()) {
          this.cptCodes.getByTerm(termId).then(() => {
            this.filterCptCodes();
            this.updateResults();
          });
        }
      },
      filterCptCodes: function () {
        if (Array.isArray(this.cptCodes.items) && this.term.name?.length === 5) {
          const filtered = this.cptCodes.items.filter(({code}) => this.term.name.toUpperCase() === code.toUpperCase());
          if (filtered.length) {
            this.cptCodes.items = filtered;
          }
        }
      },
      resetSearch: function () {
        this.searchInProgress = true;
        this.resultsCompleted = 0;
        this.backgrounds.items = [];
        this.guidelines.items = [];
        this.cptCodes.items = [];
      },
      clickBackground(background) {
        this.$router.push({
          name: 'clinical-context',
          params: {id: background.context_id},
          query: {
            q: this.term.name,
            t: this.term.id
          }
        });
      },
      clickGuideline(guideline) {
        this.$router.push({
          name: 'guidelines',
          params: {id: guideline.id},
          query: {
            q: this.term.name,
            t: this.term.id
          }
        });
      },
      updateResults() {
        this.resultsCompleted++;
        if (
            (this.$currentUser.loggedIn() && this.resultsCompleted === 3)
            || (!this.$currentUser.loggedIn() && this.resultsCompleted === 2)
        ) {
          this.searchInProgress = false;
          this.$emit('completed');
          if (!this.backgrounds.items.length && !this.guidelines.items.length && !this.cptCodes.items.length) {
            this.$emit('no-results');
          }
        }
      }
    },
    watch: {
      "term": {
        handler: function (val) {
          this.search(val?.id);
        },
        deep: true
      },
    },
    mounted() {
      if (this.term?.id) {
        this.search(this.term.id);
      } else {
        this.$emit('no-results');
      }
    }
  }
</script>

<style scoped>
  .no-cpts {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #333333;
  }

  .cpt-code-container {
    overflow-x: auto;
  }

</style>

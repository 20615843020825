<template>
  <VContainer fluid>
    <VRow dense>
      <VCol v-for="(column, index) in postColumns" :key="'column_' + index">
        <div v-for="(post, postIndex) in column" :key="'post_' + postIndex" class="mb-5">
          <button
              v-on:click="$emit('clicked', post)"
              class="post-list-item"
          >
            {{ post.title }}
          </button>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
  export default {
    data() {
      return {
        postsPerColumn: 12
      }
    },
    props: {
      posts: {
        type: [],
        default: []
      },
      columns: {
        type: Number,
        default: 2
      }
    },
    computed: {
      postColumns: function () {
        let ret = [];
        for (let i = 0; i < this.columns; i++) {
          ret[i] = this.posts.slice(i * this.postsPerColumn, (i + 1) * this.postsPerColumn);
        }
        return ret;
      }
    }
  }
</script>

<style scoped>

  button {
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    color: #004568;
  }

  button:hover {
    color: #45B98A;
  }
</style>